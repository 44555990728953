import React from "react";

interface QuestionTitleProps {
  responsePreview?: string;
  title: string;
  error?: boolean;
}

const QuestionTitle: React.FC<QuestionTitleProps> = ({ error, title }) => {
  return (
    <div className="flex flex-row items-center justify-between md:mb-1">
      <div className="flex items-center">
        <div
          className={
            "flex-1 text-sm " +
            (error ? "text-red-500" : "text-lightContentSecondary")
          }
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default QuestionTitle;
