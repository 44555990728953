import React from "react";
import { formatNumber } from "../utils";

interface ChartLegendProps {
  bulletColor: string;
  suffix?: string;
  title: string;
  value: number;
  textSize?: "text-xs" | "text-sm";
  smDirection?: "flex-col" | "flex-row";
  smMarginY?: "my-6" | "my-1";
}

const ChartLegend: React.FC<ChartLegendProps> = ({
  bulletColor,
  title,
  suffix,
  value,
  textSize = "text-sm",
  smDirection = "flex-col",
  smMarginY = "my-6",
}) => {
  return (
    <div
      className={
        "flex justify-between items-start text-black100 md:flex-row md:items-center md:my-0 " +
        smDirection +
        " " +
        smMarginY
      }
    >
      <div className="flex flex-1 items-center md:py-1">
        <div
          style={{ minWidth: "0.75rem", minHeight: "0.75rem" }}
          className={`mr-3 ${bulletColor} rounded-lg`}
        />
        <div
          className={
            textSize === "text-xs"
              ? "text-2xs md:text-xs"
              : "text-xs md:text-base"
          }
        >
          {title}
        </div>
      </div>
      <div className={"ml-6 " + textSize}>
        {(suffix ? "" : "₹") + formatNumber(value.toString()) + (suffix ?? "")}
      </div>
    </div>
  );
};

export default ChartLegend;
