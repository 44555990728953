import React from "react";
import { pieColorsV2, formatNumber, legendProp } from "../../utils";
import ChartLegend from "./chartLegend";
import DonutChart from "./donutChart";
import ResultLayout from "./resultLayout";

interface DonutResultProps {
  data: { name: string; value: number }[];
  extendedLegends?: legendProp[];
  chartWidth?: string | number;
  chartHeight?: string | number;
  chartAspect?: number;
  totalData?: legendProp;
  assumptions?: string[];
}

const DonutResult: React.FC<DonutResultProps> = ({
  assumptions,
  data,
  extendedLegends,
  totalData,
  chartWidth,
  chartHeight,
  chartAspect,
}) => {
  return (
    <ResultLayout>
      <div className="flex flex-col justify-between items-center w-full h-full md:justify-center md:px-0 md:flex-row md:gap-20 ">
        <div className="w-1/2 md:h-40 h-32 md:w-1/3">
          <DonutChart
            width={chartWidth}
            height={chartHeight}
            aspect={chartAspect}
            data={data}
          />
        </div>
        <div className="flex flex-col text-black100 mt-2 md:text-black80 w-full md:w-2/3">
          <div>
            {data.map((legend, idx) => (
              <ChartLegend
                key={idx}
                title={legend.name}
                value={legend.value}
                bulletColor={pieColorsV2[idx]}
              />
            ))}
          </div>
          <div className="flex flex-row items-center w-full pt-2 md:flex-col md:p-0">
            {totalData && (
              <>
                <div className="w-full flex justify-between items-center text-black80 font-medium md:text-black100">
                  <div className="pt-2 text-base md:text-sm text-lightContentSecondary">
                    {totalData.name}
                  </div>
                  <div
                    className="text-base pt-2 text-lightContentInteractive md:text-lg"
                    style={{ fontWeight: 700 }}
                  >
                    <span
                      className="text-lightContentSecondary"
                      style={{ fontWeight: 400 }}
                    >
                      ₹{" "}
                    </span>
                    {formatNumber(totalData.value.toString())}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </ResultLayout>
  );
};

export default DonutResult;
