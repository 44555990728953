import React from "react";

interface BannerProps {
  description: string;
}

const Banner: React.FC<BannerProps> = ({ description }) => {
  return (
    <div className="flex flex-row items-center justify-between md:mb-1">
      <div
        className="flex items-center"
        style={{
          background: "#CDEECB",
          color: "#357065",
          padding: "8px 8px 8px 4px",
          borderRadius: "4px",
        }}
      >
        <img className="w-4 h-4 mx-2" src="/assets/star-icon.svg" alt="" />
        <div className={"text-xs text-black80 md:text-xs"}>{description}</div>
      </div>
    </div>
  );
};

export default Banner;
