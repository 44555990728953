import React from "react";
import RangeSlider from "./rangeSlider";
import NumberFieldSection from "../../components/v2/numberFieldSection";

interface SliderSectionProps {
  thumbRef?: React.RefObject<HTMLDivElement>;
  trackRef?: React.RefObject<HTMLDivElement>;
  disabledWidth?: number;
  disabledPosition?: number;
  title: string;
  description?: string;
  value: number;
  suffix?: string;
  prefix?: string;
  previewSuffix?: string;
  showPreview?: boolean;
  max: number;
  min: number;
  step?: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

const SliderSection: React.FC<SliderSectionProps> = ({
  thumbRef,
  trackRef,
  disabledWidth,
  disabledPosition,
  title,
  description,
  value,
  max,
  min,
  suffix,
  previewSuffix,
  prefix,
  showPreview,
  step,
  setValue,
}) => {
  return (
    <div className="pt-4 md:py-4">
      <NumberFieldSection
        setValue={setValue}
        min={min}
        max={max}
        title={title}
        value={value}
        prefix={prefix}
        suffix={suffix}
        responsePreview={showPreview !== false ? value.toString() : ""}
      />

      <RangeSlider
        value={value}
        onChange={setValue}
        max={max}
        min={min}
        step={step}
        disabledWidth={disabledWidth}
        disabledPosition={disabledPosition}
        thumbRef={thumbRef}
        trackRef={trackRef}
      />
    </div>
  );
};

export default SliderSection;
