import React, { useState } from "react";
import NumberField from "./numberField";
import QuestionTitle from "./questionTitle";

interface NumberFieldSectionProps {
  title: string;
  description?: string;
  allowDecimal?: boolean;
  value: number;
  min?: number;
  max?: number;
  optional?: boolean;
  suffix?: string;
  prefix?: string;
  previewSuffix?: string;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

const NumberFieldSection: React.FC<NumberFieldSectionProps> = ({
  description,
  title,
  value,
  optional = false,
  min,
  max,
  allowDecimal,
  previewSuffix,
  setValue,
  prefix,
  suffix,
}) => {
  const [error, setError] = useState<"min" | "max" | "empty" | undefined>();

  return (
    <div className="pt-4 md:py-4">
      <QuestionTitle error={error ? true : false} title={title} />
      {description && (
        <div
          className={
            "mb-2 text-2xs text-black40 md:mb-3 md:text-sm md:text-black80"
          }
        >
          {description}
        </div>
      )}
      <NumberField
        min={min}
        allowDecimal={allowDecimal}
        max={max}
        optional={optional}
        value={value}
        setValue={setValue}
        prefix={prefix}
        errorMessage={
          error === "min"
            ? `${title} cannot be less than ${min}.`
            : error === "max"
            ? `${title} cannot be more than ${max}.`
            : error === "empty"
            ? `${title} cannot be empty.`
            : undefined
        }
        setError={setError}
      />
    </div>
  );
};

export default NumberFieldSection;
