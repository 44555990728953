import React, { useMemo } from "react";
import CalculatorLayout from "../components/calculatorLayout";
import DonutResult from "../components/donutResult";
import DropDown from "../components/dropDown";
import FormLayout from "../components/formLayout";
import NumberFieldSection from "../components/numberFieldSection";
import SelectSection from "../components/selectSection";

const durationUnits: string[] = [
  "Days",
  "Months",
  "Quarters",
  "Half-Years",
  "Years",
];

interface InterestData {
  principal: number;
  interest: number;
  totalAmount: number;
}

function calculateInterest(
  type: number,
  compoundFrequency: number,
  principal: number,
  rate: number,
  durationUnit: number,
  duration: number
): InterestData {
  if (type) {
    let n,
      durationMultiplier = 1;
    if (compoundFrequency === 0) {
      n = 365;
      if (durationUnit === 0) durationMultiplier = 1;
      else if (durationUnit === 1) durationMultiplier = 30;
      else if (durationUnit === 2) durationMultiplier = 91;
      else if (durationUnit === 3) durationMultiplier = 182;
      else durationMultiplier = 365;
    } else if (compoundFrequency === 1) {
      n = 12;
      if (durationUnit === 0) durationMultiplier = 1;
      else if (durationUnit === 1) durationMultiplier = 3;
      else if (durationUnit === 2) durationMultiplier = 6;
      else durationMultiplier = 12;
    } else if (compoundFrequency === 2) {
      n = 4;
      if (durationUnit === 0) durationMultiplier = 1;
      else if (durationUnit === 1) durationMultiplier = 2;
      else durationMultiplier = 4;
    } else if (compoundFrequency === 3) {
      n = 2;
      if (durationUnit === 0) durationMultiplier = 1;
      else durationMultiplier = 2;
    } else n = 1;

    const totalAmount = Math.round(
      principal * Math.pow(1 + rate / 100 / n, durationMultiplier * duration)
    );
    return {
      principal,
      interest: totalAmount - principal,
      totalAmount,
    };
  }
  let t = 0;
  if (durationUnit === 0) t = duration / 365;
  else if (durationUnit === 1) t = duration / 12;
  else if (durationUnit === 2) t = duration / 4;
  else if (durationUnit === 3) t = duration / 2;
  else if (durationUnit === 4) t = duration;
  const totalAmount = Math.round(principal * (1 + (rate / 100) * t));
  return {
    principal,
    interest: totalAmount - principal,
    totalAmount,
  };
}

const InterestCalculator: React.FC = () => {
  const [type, setType] = React.useState<number>(0);
  const [compoundFrequency, setCompoundFrequency] = React.useState<number>(4);
  const [principal, setPrincipal] = React.useState<number>(10000);
  const [rate, setRate] = React.useState<number>(10);
  const [durationUnit, setDurationUnit] = React.useState<number>(4);
  const [duration, setDuration] = React.useState<number>(10);

  const interestData: InterestData = useMemo(
    () =>
      calculateInterest(
        type,
        compoundFrequency,
        principal,
        rate,
        durationUnit,
        duration
      ),
    [type, compoundFrequency, principal, rate, durationUnit, duration]
  );

  return (
    <CalculatorLayout>
      <DonutResult
        data={[
          { name: "Principal", value: principal },
          { name: "Interest", value: interestData.interest },
        ]}
        totalData={{ name: "Total Amount", value: interestData.totalAmount }}
      />
      <FormLayout>
        <SelectSection
          index={type}
          options={["Simple Interest", "Compound Interest"]}
          setIndex={(newType: number) => {
            if (newType !== type) {
              const newDurationUnit = newType
                ? durationUnits
                    .slice(compoundFrequency)
                    .indexOf(durationUnits[durationUnit])
                : durationUnits.indexOf(
                    durationUnits.slice(compoundFrequency)[durationUnit]
                  );
              setDurationUnit(newDurationUnit);
              setType(newType);
            }
          }}
          title="Type of Interest"
          description="Choose the type of interest you want to calculate"
        />
        {type ? (
          <SelectSection
            index={compoundFrequency}
            options={["Daily", "Monthly", "Quarterly", "Half-Yearly", "Yearly"]}
            setIndex={(newFreq: number) => {
              const newDurationUnit = durationUnits
                .slice(newFreq)
                .indexOf(durationUnits.slice(compoundFrequency)[durationUnit]);
              setDurationUnit(newDurationUnit >= 0 ? newDurationUnit : 0);
              setCompoundFrequency(newFreq);
            }}
            title="Compound Frequency"
            description="What is your compounding period in a year?"
          />
        ) : null}
        <NumberFieldSection
          setValue={setPrincipal}
          title="Principal Amount"
          description="How much do you wish to invest/borrow?"
          value={principal}
          min={0}
          prefix="₹"
        />
        <NumberFieldSection
          setValue={setRate}
          title="Rate of Interest"
          description="What is the Rate of Interest per year?"
          value={rate}
          min={1}
          max={30}
        />
        <div className="flex items-start">
          <NumberFieldSection
            setValue={setDuration}
            title="Duration"
            description="Duration of your investment/loan"
            value={duration}
            allowDecimal={false}
            min={1}
          />
          <div className="flex-1 ml-4 mt-10 md:mt-12">
            <DropDown
              index={durationUnit}
              options={
                type ? durationUnits.slice(compoundFrequency) : durationUnits
              }
              setIndex={setDurationUnit}
            />
          </div>
        </div>
      </FormLayout>
    </CalculatorLayout>
  );
};

export default InterestCalculator;
