import React, { useMemo, useRef, useState } from "react";
import CalculatorLayout from "../components/calculatorLayout";
import DonutResult from "../components/donutResult";
import QuestionLayout from "../components/formLayout";
import NumberFieldSection from "../components/numberFieldSection";
import SliderSection from "../components/sliderSection";

const interestRate = 8.5;
const minSalary = 4500;

interface EpfData {
  interest: number;
  totalBalance: number;
  totalContribution: number;
}

function calculateEPF(
  currentAge: number,
  epfBalance: number,
  monthlyEpfContribution: number,
  retirementAge: number,
  salary: number
): EpfData {
  if (retirementAge < currentAge) retirementAge = currentAge + 1;
  const totalMonths: number = (retirementAge - currentAge) * 12;

  const totalContribution: number = monthlyEpfContribution * totalMonths;

  let totalEpfBalance: number = epfBalance;
  for (let i = 0; i < totalMonths; i++) {
    totalEpfBalance =
      (totalEpfBalance + monthlyEpfContribution) * (1 + interestRate / 1200);
  }
  return {
    interest: Math.ceil(totalEpfBalance - totalContribution),
    totalBalance: Math.ceil(totalEpfBalance),
    totalContribution: Math.ceil(totalContribution),
  };
}

const EpfCalculator: React.FC = () => {
  const [age, setAge] = useState<number>(30);
  const [retirementAge, setRetirementAge] = useState<number>(55);
  const [salary, setSalary] = useState<number>(50000);
  const [epfBalance, setEpfBalance] = useState<number>(0);
  const [epfContribution, setEpfContribution] = useState<number>(1200);

  const [disabledTrackWidth, setDisabledTrackWidth] = useState<number>(0);

  const thumbRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);

  const epfData: EpfData = useMemo(
    () => calculateEPF(age, epfBalance, epfContribution, retirementAge, salary),
    [age, epfBalance, epfContribution, retirementAge, salary]
  );

  return (
    <CalculatorLayout>
      <DonutResult
        assumptions={[
          "Employer contribution of 12% is split into : 3.67% to EPF and 8.33% to EPS.",
          `Assuming Interest Rate of ${interestRate}% FY 20-21`,
        ]}
        data={[
          {
            name: "Total Investment",
            value: epfData.totalContribution,
          },
          { name: "Interest", value: epfData.interest },
        ]}
        totalData={{ name: "Total EPF Value", value: epfData.totalBalance }}
      />
      <QuestionLayout>
        <SliderSection
          title="Your Current Age"
          description="Tell us your age"
          value={age}
          setValue={(newValue: number) => {
            setAge(newValue);
            setDisabledTrackWidth(
              (thumbRef.current?.getBoundingClientRect().x ?? 0) -
                (trackRef.current?.getBoundingClientRect().x ?? 0)
            );
          }}
          suffix=" yrs"
          min={18}
          max={59}
          thumbRef={thumbRef}
          trackRef={trackRef}
        />
        <SliderSection
          title="What Age Do You Want To Retire?"
          description="At what age do you wish to retire to life in the hills?"
          value={retirementAge}
          setValue={setRetirementAge}
          disabledPosition={age + 1}
          disabledWidth={disabledTrackWidth}
          min={19}
          max={60}
          suffix=" yrs"
        />
        <NumberFieldSection
          title="Monthly Basic Salary"
          description="What’s your basic salary per month? "
          value={salary}
          setValue={setSalary}
          min={minSalary}
          prefix="₹"
        />
        <NumberFieldSection
          title="Current EPF Balance (Optional)"
          description="Tell us your current EPF balance"
          optional
          value={epfBalance}
          setValue={setEpfBalance}
          prefix="₹"
        />
        <NumberFieldSection
          title="Your Monthly EPF Contribution"
          description="What’s your monthly EPF contribution?"
          min={1}
          max={15000}
          value={epfContribution}
          setValue={setEpfContribution}
          prefix="₹"
        />
      </QuestionLayout>
    </CalculatorLayout>
  );
};

export default EpfCalculator;
