import React from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import EpfCalculator from "./pages/epf";
import NpsCalculator from "./pages/nps";
import PpfCalculator from "./pages/ppf";
import RoiCalculator from "./pages/roi";
import SalaryCalculator from "./pages/salary";
import InterestCalculator from "./pages/interest";
import GratuityCalculator from "./pages/gratuity";
import HraCalculator from "./pages/hra";
import EmiCalculator from "./pages/emi";
import InterestRateCalculator from "./pages/interestRate";
import HomeLoanEmiCalculator from "./pages/HomeLoanEmi/homeLoanEmi";

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <div className="flex flex-wrap justify-center p-8">
            <Link to="/epf">
              <div className="p-4 mx-4 w-40 text-center text-black100 border border-balck60 rounded-lg cursor-pointer hover:bg-lightCyan active:bg-forestGreen">
                EPF Calculator
              </div>
            </Link>
            <Link to="/nps">
              <div className="p-4 mx-4 w-40 text-center text-black100 border border-balck60 rounded-lg cursor-pointer hover:bg-lightCyan active:bg-forestGreen">
                NPS Calculator
              </div>
            </Link>
            <Link to="/ppf">
              <div className="p-4 mx-4 w-40 text-center text-black100 border border-balck60 rounded-lg cursor-pointer hover:bg-lightCyan active:bg-forestGreen">
                PPF Calculator
              </div>
            </Link>
            <Link to="/roi">
              <div className="p-4 mx-4 w-40 text-center text-black100 border border-balck60 rounded-lg cursor-pointer hover:bg-lightCyan active:bg-forestGreen">
                ROI Calculator
              </div>
            </Link>
            <Link to="/salary">
              <div className="p-4 mx-4 w-40 text-center text-black100 border border-balck60 rounded-lg cursor-pointer hover:bg-lightCyan active:bg-forestGreen">
                Salary Calculator
              </div>
            </Link>
            <Link to="/interest">
              <div className="p-4 mx-4 w-40 text-center text-black100 border border-balck60 rounded-lg cursor-pointer hover:bg-lightCyan active:bg-forestGreen">
                Interest Calculator
              </div>
            </Link>
            <Link to="/gratuity">
              <div className="p-4 mx-4 w-40 text-center text-black100 border border-balck60 rounded-lg cursor-pointer hover:bg-lightCyan active:bg-forestGreen">
                Gratuity Calculator
              </div>
            </Link>
            <Link to="/hra">
              <div className="p-4 mx-4 w-40 text-center text-black100 border border-balck60 rounded-lg cursor-pointer hover:bg-lightCyan active:bg-forestGreen">
                HRA Calculator
              </div>
            </Link>
            <Link to="/emi">
              <div className="p-4 mx-4 w-40 text-center text-black100 border border-balck60 rounded-lg cursor-pointer hover:bg-lightCyan active:bg-forestGreen">
                EMI Calculator
              </div>
            </Link>
            <Link to="/interest-rate">
              <div className="p-4 mx-4 w-40 text-center text-black100 border border-balck60 rounded-lg cursor-pointer hover:bg-lightCyan active:bg-forestGreen">
                Interest Rate Calculator
              </div>
            </Link>
            <Link to="/home-loan-emi">
              <div className="p-4 mx-4 w-40 text-center text-black100 border border-balck60 rounded-lg cursor-pointer hover:bg-lightCyan active:bg-forestGreen">
                Home Loan EMI Calculator
              </div>
            </Link>
          </div>
        </Route>
        <Route exact path="/epf">
          <EpfCalculator />
        </Route>
        <Route exact path="/nps">
          <NpsCalculator />
        </Route>
        <Route exact path="/ppf">
          <PpfCalculator />
        </Route>
        <Route exact path="/roi">
          <RoiCalculator />
        </Route>
        <Route exact path="/salary">
          <SalaryCalculator />
        </Route>
        <Route exact path="/interest">
          <InterestCalculator />
        </Route>
        <Route exact path="/gratuity">
          <GratuityCalculator />
        </Route>
        <Route exact path="/hra">
          <HraCalculator />
        </Route>
        <Route exact path="/emi">
          <EmiCalculator />
        </Route>
        <Route exact path="/interest-rate">
          <InterestRateCalculator />
        </Route>
        <Route exact path="/home-loan-emi">
          <HomeLoanEmiCalculator />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
