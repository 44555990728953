import React, { useEffect, useRef, useState } from "react";
import { extractNumber, formatNumber } from "../utils";

interface TextFieldProps {
  prefix?: string;
  value: number;
  optional?: boolean;
  min?: number;
  max?: number;
  allowDecimal?: boolean;
  errorMessage?: string;
  setError: (newError: "min" | "max" | "empty" | undefined) => void;
  setValue:
    | React.Dispatch<React.SetStateAction<number>>
    | ((newVal: number) => void);
}

const NumberField: React.FC<TextFieldProps> = ({
  errorMessage,
  prefix,
  value,
  optional = false,
  min,
  max,
  allowDecimal = true,
  setError,
  setValue,
}) => {
  const [inputValue, setInputValue] = useState<string>(
    formatNumber(value.toString())
  );
  const [caretPosition, setCaretPosition] = useState<number>(inputValue.length);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputValue.trim() === "") {
      if (!optional) setError("empty");
      setValue(min ?? 0);
    } else if (parseFloat(extractNumber(inputValue).toString()) >= (min ?? 0)) {
      if (max) {
        if (parseFloat(extractNumber(inputValue).toString()) <= max) {
          setError(undefined);
          setValue(extractNumber(inputValue));
        } else {
          setError("max");
          setValue(max);
        }
      } else {
        setError(undefined);
        setValue(extractNumber(inputValue));
      }
    } else {
      setError("min");
      setValue(extractNumber(min?.toString() ?? "0"));
    }
  }, [optional, inputValue, max, min, setValue]);

  useEffect(() => {
    inputRef.current?.setSelectionRange(caretPosition, caretPosition);
  }, [inputValue, inputRef, caretPosition]);

  return (
    <>
      <div
        className={
          "flex pl-2 py-2 border rounded " +
          (errorMessage ? "border-red-500" : "border-black100")
        }
      >
        <span className="mr-3 text-black40 text-lg">{prefix}</span>
        <input
          ref={inputRef}
          className="w-full text-black100 text-base focus:outline-none"
          style={{ caretColor: "#075955", fontVariant: "small-caps" }}
          onChange={(e) => {
            setCaretPosition(e.target.selectionStart ?? 0);
            if (e.target.value.trim() === "") return setInputValue("");

            if (!/^[\d.]+$/.test(e.target.value.slice(-1))) {
              setInputValue(
                formatNumber(e.target.value.slice(0, -1).toString())
              );
            } else if (!allowDecimal && e.target.value.slice(-1) === ".")
              setInputValue(
                formatNumber(e.target.value.slice(0, -1).toString())
              );
            else if (
              e.target.value.endsWith(".") &&
              e.target.value.slice(0, -1).includes(".")
            ) {
              setInputValue(
                formatNumber(e.target.value.slice(0, -1).toString())
              );
            } else setInputValue(formatNumber(e.target.value.toString()));
          }}
          inputMode="numeric"
          type="text"
          value={inputValue}
        />
      </div>
      {errorMessage && (
        <div className="pt-2 text-red-500 text-xs">{errorMessage}</div>
      )}
    </>
  );
};

export default NumberField;
