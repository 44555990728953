import React, { useState } from "react";

interface TooltipProps {
  assumptions: string[];
  smHidden?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ assumptions, smHidden = true }) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  return (
    <>
      <div
        onFocus={() => setIsFocused(true)}
        tabIndex={1}
        onBlur={() => setIsFocused(false)}
        className={
          "flex flex-row items-center text-xs text-left tooltip md:justify-start md:w-full focus:outline-none " +
          (isFocused ? "text-black80" : "text-gt80")
        }
      >
        <img
          className="inline ml-2 md:p-0 md:ml-0 md:mr-2"
          src={`/assets/info${isFocused ? "_active" : ""}.svg`}
          alt="i"
        />
        <span className={"md:inline " + (smHidden ? "hidden" : "ml-1")}>
          See assumptions
        </span>
        <div className="tooltip-text">
          <div className="flex flex-col">
            {assumptions.map((assumption, idx) => {
              return (
                <div key={idx} className="flex items-center my-2 text-base">
                  <div
                    style={{ minWidth: "8px", minHeight: "8px" }}
                    className="flex-shrink-0 mr-3 bg-darkGray rounded-lg"
                  />
                  <div>{assumption}</div>
                </div>
              );
            })}
          </div>
          <div className="cover">
            <div className="tooltip-tail" />
          </div>
        </div>
      </div>
      <div className="overlay" />
    </>
  );
};

export default Tooltip;
