import React from "react";
import QuestionTitle from "./questionTitle";
import RangeSlider from "./rangeSlider";

interface SliderSectionProps {
  thumbRef?: React.RefObject<HTMLDivElement>;
  trackRef?: React.RefObject<HTMLDivElement>;
  disabledWidth?: number;
  disabledPosition?: number;
  title: string;
  description?: string;
  value: number;
  suffix?: string;
  prefix?: string;
  previewSuffix?: string;
  showPreview?: boolean;
  max: number;
  min: number;
  step?: number;
  setValue: (newValue: number) => void;
}

const SliderSection: React.FC<SliderSectionProps> = ({
  thumbRef,
  trackRef,
  disabledWidth,
  disabledPosition,
  title,
  description,
  value,
  max,
  min,
  suffix,
  previewSuffix,
  prefix,
  showPreview,
  step,
  setValue,
}) => {
  return (
    <div className="pt-4 md:py-4">
      <QuestionTitle
        title={title}
        responsePreview={
          showPreview !== false
            ? (prefix ?? "") +
              value.toString() +
              (suffix ?? "") +
              (previewSuffix ?? "")
            : ""
        }
      />
      {description && (
        <div
          className={"mb-4 text-2xs text-black40 md:text-sm md:text-black80"}
        >
          {description}
        </div>
      )}
      <RangeSlider
        value={value}
        onChange={setValue}
        max={max}
        min={min}
        step={step}
        disabledWidth={disabledWidth}
        disabledPosition={disabledPosition}
        thumbRef={thumbRef}
        trackRef={trackRef}
      />
    </div>
  );
};

export default SliderSection;
