import React, { useState } from "react";
import NumberField from "./numberField";
import QuestionTitle from "./questionTitle";
import { formatNumber } from "../../utils";

interface NumberFieldSectionProps {
  title: string;
  description?: string;
  allowDecimal?: boolean;
  value: number;
  min?: number;
  max?: number;
  optional?: boolean;
  suffix?: string;
  prefix?: string;
  previewSuffix?: string;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  responsePreview?: string;
  showPreview?: boolean;
}

const NumberFieldSection: React.FC<NumberFieldSectionProps> = ({
  description,
  title,
  value,
  optional = false,
  min,
  max,
  allowDecimal,
  previewSuffix,
  setValue,
  prefix,
  suffix,
  responsePreview,
  showPreview,
}) => {
  const [error, setError] = useState<"min" | "max" | "empty" | undefined>();
  const [inputField, setInputField] = useState<boolean>(false);

  return (
    <div className="pt-4 md:py-4 flex flex-row justify-between">
      <QuestionTitle
        error={error ? true : false}
        title={title}
        responsePreview={
          showPreview !== false
            ? (prefix ?? "") +
              value.toString() +
              (suffix ?? "") +
              (previewSuffix ?? "")
            : ""
        }
      />
      {description && (
        <div
          className={
            "mb-2 text-sm text-black40 md:mb-3 md:text-sm md:text-black80"
          }
        >
          {description}
        </div>
      )}

      {inputField ? (
        <NumberField
          min={min}
          allowDecimal={allowDecimal}
          max={max}
          optional={optional}
          value={value}
          setValue={(value: number) => setValue(value)}
          prefix={prefix}
          suffix={suffix}
          errorMessage={
            error === "min"
              ? `${title} can not be less than ${min}.`
              : error === "max"
              ? `${title} can not be more than ${max}.`
              : error === "empty"
              ? `${title} can not be empty.`
              : undefined
          }
          setError={setError}
          setInputField={setInputField}
        />
      ) : (
        <div
          className="min-w-max text-lightContentPrimary text-base border-b border-gray100 cursor-pointer "
          onClick={() => setInputField(true)}
        >
          <span className="text-lightContentSecondary text-base ">
            {prefix}
          </span>
          <span
            className="text-lightContentPrimary text-base"
            style={{ fontWeight: 600 }}
          >
            {" "}
            {formatNumber(responsePreview)}
          </span>
          <span className="text-lightContentSecondary text-base">
            {" "}
            {suffix}
          </span>
        </div>
      )}
    </div>
  );
};

export default NumberFieldSection;
