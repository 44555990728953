import React from "react";

interface MainLayoutProps {
  children?: React.ReactNode;
  direction?: "md:flex-row" | "md:flex-col";
  title?: string;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  direction,
  title,
}) => {
  return (
    <div className="w-full h-full pt-4 bg-white font-sans text-sm font-normal">
      <div
        className={
          "flex items-stretch px-3 bg-white rounded-lg flex-col md:px-4 md:mb-4 " +
          (direction ? direction : "md:flex-row")
        }
      >
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
