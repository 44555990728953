import React, { useEffect, useRef, useState } from "react";
import { extractNumber, formatNumber } from "../../utils";

interface TextFieldProps {
  prefix?: string;
  suffix?: string;
  value: number;
  optional?: boolean;
  min?: number;
  max?: number;
  allowDecimal?: boolean;
  errorMessage?: string;
  setError: (newError: "min" | "max" | "empty" | undefined) => void;
  setInputField: (ip: boolean) => void;
  setValue:
    | React.Dispatch<React.SetStateAction<number>>
    | ((newVal: number) => void);
}

const NumberField: React.FC<TextFieldProps> = ({
  errorMessage,
  prefix,
  suffix,
  value,
  optional = false,
  min,
  max,
  allowDecimal = true,
  setError,
  setValue,
  setInputField,
}) => {
  const [inputValue, setInputValue] = useState<string>(
    formatNumber(value.toString())
  );
  const [caretPosition, setCaretPosition] = useState<number>(
    inputValue.includes(",") ? inputValue.length + 1 : inputValue.length
  );
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputValue.trim() === "") {
      if (!optional) setError("empty");
      setValue(min ?? 0);
    } else if (parseFloat(extractNumber(inputValue).toString()) >= (min ?? 0)) {
      if (max) {
        if (parseFloat(extractNumber(inputValue).toString()) <= max) {
          setError(undefined);
          setValue(extractNumber(inputValue));
        } else {
          setError("max");
          setValue(extractNumber(inputValue));
        }
      } else {
        setError(undefined);
        setValue(extractNumber(inputValue));
      }
    } else {
      setError("min");
      //  setValue(extractNumber(min?.toString() ?? "0"));
      setValue(extractNumber(inputValue));
    }
  }, [optional, inputValue, max, min, setValue, value]);

  useEffect(() => {
    inputRef.current?.setSelectionRange(caretPosition, caretPosition);
  }, [inputValue, inputRef, caretPosition]);

  return (
    <div className="flex">
      <div
        className={"flex pl-2 items-center "}
        style={{ justifyContent: "flex-end" }}
      >
        <div
          className={
            "flex pl-1 items-center " +
            (errorMessage ? "border-red-500" : "border-gray100")
          }
        >
          {errorMessage && (
            <div className="mr-3 text-red-500 text-xs">{errorMessage}</div>
          )}
          <span className="text-lightContentSecondary text-base pr-1">
            {prefix}
          </span>
          <span>
            <input
              ref={inputRef}
              min={min}
              max={max}
              className="text-black100 text-base border border-black100 focus:outline-none mr-1"
              style={{
                caretColor: "#075955",
                fontVariant: "small-caps",
                maxWidth: "126px",
                fontWeight: 600,
                borderRadius: "4px",
                padding: "0px 4px",
                height: "25px",
              }}
              onChange={(e) => {
                setCaretPosition(e.target.selectionStart ?? 0);
                if (e.target.value.trim() === "") return setInputValue("");

                if (!/^[\d.]+$/.test(e.target.value.slice(-1))) {
                  setInputValue(
                    formatNumber(e.target.value.slice(0, -1).toString())
                  );
                } else if (!allowDecimal && e.target.value.slice(-1) === ".")
                  setInputValue(
                    formatNumber(e.target.value.slice(0, -1).toString())
                  );
                else if (
                  e.target.value.endsWith(".") &&
                  e.target.value.slice(0, -1).includes(".")
                ) {
                  setInputValue(
                    formatNumber(e.target.value.slice(0, -1).toString())
                  );
                } else {
                  setInputValue(formatNumber(e.target.value.toString()));
                }
              }}
              onBlur={() => {
                setInputField(false);
              }}
              inputMode="numeric"
              type="text"
              value={inputValue}
            />
          </span>
          <span className="text-lightContentSecondary text-base">{suffix}</span>
        </div>
      </div>
    </div>
  );
};

export default NumberField;
