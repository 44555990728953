import React from "react";
import { useWindowDimensions, __md_breakpoint__ } from "../utils";
import FooterCTA from "./footerCTA";

interface FormLayoutProps {
  children?: React.ReactNode;
}

const FormLayout: React.FC<FormLayoutProps> = ({ children }) => {
  const { width: screenWidth } = useWindowDimensions();
  return (
    <div className="flex-1 h-full flex flex-col rounded-lg">
      <div className="hidden md:block w-full p-2 bg-gt20 text-center text-xs text-gt60 border border-transparent rounded-lg rounded-b-none">
        Calculate Instantly
      </div>
      <div className="h-full px-3 pt-4 pb-8 border border-t-0 border-b-0 border-gray100 rounded-t-none md:px-6 md:pt-0 md:pb-0 md:border-b md:rounded-lg">
        {children}
      </div>
      {screenWidth < __md_breakpoint__ && <FooterCTA />}
    </div>
  );
};

export default FormLayout;
