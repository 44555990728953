import React, { useEffect, useState } from "react";

interface DropDownProps {
  options: string[];
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

const DropDown: React.FC<DropDownProps> = ({ options, index, setIndex }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    const hideMenuOnClickOutside = () => {};
    document.addEventListener("click", hideMenuOnClickOutside);
    return () => document.removeEventListener("click", hideMenuOnClickOutside);
  }, []);
  return (
    <div className="relative w-max select-none">
      <div
        className="flex items-center w-full text-sm text-lightContentSecondary border-b border-lightContentPrimary cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {options[index]}
        <img
          className="w-2 h-2 mx-2 my-2"
          src="/assets/down-chevron.svg"
          alt=""
        />
      </div>
      <div className="absolute top-6 w-full z-10">
        {isOpen
          ? options.map((option, idx) => (
              <p
                className={`bg-white text-xs px-2 py-1 border ${
                  idx === 0 ? "" : "border-t-0"
                } hover:bg-lightContentPrimary hover:text-white hover:border-lightContentPrimary`}
                onClick={() => {
                  setIndex(idx);
                  setIsOpen(!isOpen);
                }}
              >
                {option}
              </p>
            ))
          : null}
      </div>
    </div>
  );
};

export default DropDown;
