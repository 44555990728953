import React, { useState } from "react";

interface CollapsibleProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

const Collapsible: React.FC<CollapsibleProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ borderBottom: "1px solid rgb(43, 45, 51, 0.12)" }}>
      <div
        style={{ cursor: "pointer", backgroundColor: "#FFF" }}
        onClick={toggleCollapsible}
      >
        {title}
      </div>
      <div
        id="collpsible-content"
        style={{
          height: isOpen ? `auto` : 0,
          opacity: isOpen ? 1 : 0,
          transition: "height 0.4s",
        }}
      >
        {isOpen && <div>{children}</div>}
      </div>
    </div>
  );
};

export default Collapsible;
