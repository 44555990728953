import React, { useMemo, useState } from "react";
import CalculatorLayout from "../components/calculatorLayout";
import { __interest_rate__ } from "../utils";
import FormLayout from "../components/formLayout";
import SelectSection from "../components/selectSection";
import SliderSection from "../components/sliderSection";
import NumberFieldSection from "../components/numberFieldSection";
import DonutResult from "../components/donutResult";

const yearlyInvestmentMin = 6000;
const yearlyInvestmentMax = 150000;

interface PpfData {
  maturityValue: number;
  totalInterest: number;
  totalInvestment: number;
}

function calculatePpf(
  ppfBalance: number,
  numberOfYears: number,
  investmentAmount: number,
  investmentFrequencyOption: number
): PpfData {
  let add: number = 0;
  let investmentFrequency = 0;
  if (investmentFrequencyOption === 0) {
    add =
      investmentAmount * (__interest_rate__ / 1200) * 6 * 13 +
      investmentAmount * 12 +
      ppfBalance;
    investmentFrequency = 12;
  } else if (investmentFrequencyOption === 1) {
    add =
      investmentAmount * (__interest_rate__ / 1200) * 30 +
      investmentAmount * 4 +
      ppfBalance;
    investmentFrequency = 4;
  } else if (investmentFrequencyOption === 2) {
    add =
      investmentAmount * (__interest_rate__ / 1200) * 18 +
      investmentAmount * 2 +
      ppfBalance;
    investmentFrequency = 2;
  } else {
    add =
      investmentAmount * (__interest_rate__ / 1200) * 12 +
      investmentAmount +
      ppfBalance;
    investmentFrequency = 1;
  }
  let maturityValue: number = 0;
  for (let i = 0; i < numberOfYears; i++) {
    maturityValue = maturityValue * (1 + __interest_rate__ / 100) + add;
  }
  const totalInvestment = Math.ceil(
    investmentAmount * numberOfYears * investmentFrequency
  );
  const totalInterest: number = Math.ceil(maturityValue) - totalInvestment;

  return {
    maturityValue: Math.ceil(maturityValue),
    totalInterest,
    totalInvestment,
  };
}

const PpfCalculator: React.FC = () => {
  const [numberOfYears, setNumberOfYears] = useState<number>(30);
  const [investmentAmount, setInvestmentAmount] = useState<number>(8000);
  const [investmentFrequency, setInvestmentFrequency] = useState<number>(0);
  const [ppfBalance, setPpfBalance] = useState<number>(0);

  const [investmentMin, setInvestmentMin] = useState<number>(
    yearlyInvestmentMin / 12
  );
  const [investmentMax, setInvestmentMax] = useState<number>(
    yearlyInvestmentMax / 12
  );

  const ppfData = useMemo(
    () =>
      calculatePpf(
        ppfBalance,
        numberOfYears,
        investmentAmount,
        investmentFrequency
      ),
    [ppfBalance, numberOfYears, investmentAmount, investmentFrequency]
  );

  return (
    <CalculatorLayout>
      <DonutResult
        data={[
          { name: "Total Investment", value: ppfData.totalInvestment },
          { name: "Total Interest", value: ppfData.totalInterest },
        ]}
        assumptions={[
          `Assuming Current Interest Rate to be ${__interest_rate__}% (2021)`,
          "Assuming that amount is compounded yearly",
        ]}
        totalData={{ name: "Maturity Value", value: ppfData.maturityValue }}
      />
      <FormLayout>
        <SelectSection
          title="Frequency of Investment"
          description="How often do you wish to invest?"
          index={investmentFrequency}
          setIndex={(newIndex: number) => {
            if (newIndex === 0) {
              setInvestmentMin(yearlyInvestmentMin / 12);
              setInvestmentMax(yearlyInvestmentMax / 12);
            } else if (newIndex === 1) {
              setInvestmentMin(yearlyInvestmentMin / 4);
              setInvestmentMax(yearlyInvestmentMax / 4);
            } else if (newIndex === 2) {
              setInvestmentMin(yearlyInvestmentMin / 2);
              setInvestmentMax(yearlyInvestmentMax / 2);
            } else {
              setInvestmentMin(yearlyInvestmentMin);
              setInvestmentMax(yearlyInvestmentMax);
            }
            setInvestmentFrequency(newIndex);
          }}
          options={["Monthly", "Quarterly", "Half-Yearly", "Yearly"]}
        />
        <NumberFieldSection
          title="Deposit Amount"
          description="How much do you wish to deposit?"
          value={investmentAmount}
          setValue={setInvestmentAmount}
          prefix="₹"
          min={investmentMin}
          max={investmentMax}
        />
        <NumberFieldSection
          title="Current PPF Balance (Optional)"
          description="What’s your current PPF balance?"
          optional
          value={ppfBalance}
          setValue={setPpfBalance}
          prefix="₹"
        />
        <SliderSection
          title="Duration of Investment"
          description="For how long do you plan to invest?"
          value={numberOfYears}
          setValue={setNumberOfYears}
          min={15}
          max={70}
          step={5}
          suffix=" yrs"
        />
      </FormLayout>
    </CalculatorLayout>
  );
};

export default PpfCalculator;
