import React from "react";

interface SelectProps {
  index?: number;
  options: string[];
  setIndex: (index: number) => void;
}

interface SelectOptionProps {
  index: number;
  selected: boolean;
  text: string;
  onSelect: (index: number) => void;
}

const Select: React.FC<SelectProps> = ({ index, options, setIndex }) => {
  const selectHandler: (idx: number) => void = (idx: number) => {
    setIndex(idx);
  };

  return (
    <div className="flex justify-start">
      {options.map((option, idx) => (
        <SelectOption
          key={idx}
          index={idx}
          text={option}
          selected={index === idx}
          onSelect={selectHandler}
        />
      ))}
    </div>
  );
};

const SelectOption: React.FC<SelectOptionProps> = ({
  index,
  text,
  selected,
  onSelect,
}) => {
  return (
    <div
      onClick={() => onSelect(index)}
      className={
        "flex items-center justify-center px-3 py-1 mr-3 text-center text-xs border border-gt80 rounded-lg cursor-pointer md:px-3 md:py-2 " +
        (selected ? "bg-gt80 text-white" : "text-gt80 hover:bg-lightCyan")
      }
    >
      {text}
    </div>
  );
};

export default Select;
