import React, { useState } from "react";
import { useMemo } from "react";
import CalculatorLayout from "../components/calculatorLayout";
import DonutResult from "../components/donutResult";
import FormLayout from "../components/formLayout";
import NumberFieldSection from "../components/numberFieldSection";
import SliderSection from "../components/sliderSection";

interface RoiData {
  absoluteRoi: number;
  cagr: number;
  gain: number;
  roi: number;
}

function calculateCagr(
  duration: number,
  investmentAmount: number,
  returnAmount: number
): number {
  if (returnAmount < 0) return 0;
  let s: number = 0;
  let e: number = 100000;

  while (s <= e) {
    let mid: number = (s + e) / 2;
    let temp: number = investmentAmount;
    for (let i = 0; i < duration; i++) {
      temp = temp * mid;
    }
    if (temp > returnAmount) {
      e = mid - 0.0001;
    } else {
      s = mid + 0.0001;
    }
  }
  return Math.ceil((s - 1) * 100);
}

function calculateRoi(
  duration: number,
  investmentAmount: number,
  returnAmount: number
): RoiData {
  const gain: number = returnAmount - investmentAmount;
  const roi: number = Math.ceil(((gain * 100) / investmentAmount) * 100) / 100;
  const absoluteRoi = Math.ceil(
    ((returnAmount - investmentAmount) / investmentAmount) *
      100 *
      (1 / duration)
  );

  const cagr = calculateCagr(duration, investmentAmount, returnAmount);

  return {
    absoluteRoi: absoluteRoi,
    cagr,
    gain,
    roi,
  };
}

const RoiCalculator: React.FC = () => {
  const [investmentAmount, setInvestmentAmount] = useState<number>(10000);
  const [returnAmount, setReturnAmount] = useState<number>(100000);
  const [numberOfYears, setNumberOfYears] = useState<number>(8);

  const roiData = useMemo(
    () => calculateRoi(numberOfYears, investmentAmount, returnAmount),
    [numberOfYears, investmentAmount, returnAmount]
  );

  return (
    <CalculatorLayout>
      <DonutResult
        chartWidth="100%"
        chartAspect={1}
        data={[
          { name: "Invested Amount", value: investmentAmount },
          {
            name:
              roiData.gain >= 0 ? "Gain on Investment" : "Loss on Investment",
            value: Math.abs(roiData.gain),
          },
        ]}
        extendedLegends={[
          {
            name: "Return on Investment (ROI)",
            value: roiData.roi,
            suffix: "%",
          },
          { name: "Absolute ROI", value: roiData.absoluteRoi, suffix: "%" },
          { name: "CAGR", value: roiData.cagr, suffix: "%" },
        ]}
      />
      <FormLayout>
        <NumberFieldSection
          title="Investment Amount"
          description="How much do you wish to invest?"
          value={investmentAmount}
          setValue={setInvestmentAmount}
          prefix="₹"
        />
        <NumberFieldSection
          title="Expected Returns"
          description="What are your expected returns?"
          value={returnAmount}
          setValue={setReturnAmount}
          prefix="₹"
        />
        <SliderSection
          title="Investment Period"
          description="For how long do you plan to invest?"
          min={1}
          max={70}
          value={numberOfYears}
          setValue={setNumberOfYears}
          suffix=" yrs"
        />
      </FormLayout>
    </CalculatorLayout>
  );
};

export default RoiCalculator;
