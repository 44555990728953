import React, { useMemo, useState } from "react";
import CalculatorLayout from "../components/calculatorLayout";
import ChartLegend from "../components/chartLegend";
import DonutChart from "../components/donutChart";
import QuestionLayout from "../components/formLayout";
import MultipleNumberFieldSection from "../components/multipleNumberFieldSection";
import ResultLayout from "../components/resultLayout";
import Toggle from "../components/toggle";
import Tooltip from "../components/tooltip";
import {
  formatNumber,
  pieColors,
  useWindowDimensions,
  __md_breakpoint__,
} from "../utils";

interface SalaryData {
  basicSalary: number;
  hra: number;
  netSalary: number;
  providentFund: number;
  professionalTax: number;
  otherAllowances: number;
  incomeTax: number;
  postTaxSalary: number;
}

function calculateSalary(
  basicSalaryRate: number,
  showMonthly: boolean,
  bonus: number,
  grossSalary: number
): SalaryData {
  const ctc: number = grossSalary + bonus;
  const basicSalary: number = grossSalary * basicSalaryRate;
  const hra: number = grossSalary * 0.25;

  const providentFund: number = 0.12 * basicSalary;
  const professionalTax: number = 2400;
  const taxableSalary: number =
    ctc - providentFund - professionalTax - hra - 150000;
  const otherAllowances = grossSalary - basicSalary - hra;
  const netSalary: number =
    basicSalary + hra + otherAllowances - providentFund - professionalTax;

  let incomeTax: number = 0;
  // if (taxableSalary <= 250000) incomeTax = 0;
  // else if (taxableSalary <= 500000) incomeTax = 0.05 * taxableSalary;
  // else if (taxableSalary <= 1000000) incomeTax = 0.2 * taxableSalary;
  // else incomeTax = 0.3 * taxableSalary;

  if (taxableSalary >= 500000) {
    incomeTax += 0.05 * 100000;
    if (taxableSalary > 500000) {
      if (taxableSalary > 1000000) {
        incomeTax += 0.2 * 500000;
        incomeTax += 0.3 * (taxableSalary - 1000000);
      } else incomeTax += (taxableSalary - 500000) * 0.2;
    }
  }

  if (showMonthly)
    return {
      basicSalary: Math.ceil(basicSalary / 12),
      hra: Math.ceil(hra / 12),
      netSalary: Math.ceil(netSalary / 12),
      providentFund: Math.ceil(providentFund / 12),
      professionalTax: Math.ceil(professionalTax / 12),
      otherAllowances: Math.ceil(otherAllowances / 12),
      incomeTax: Math.ceil(incomeTax / 12),
      postTaxSalary: Math.ceil((netSalary - incomeTax) / 12),
    };
  return {
    basicSalary,
    hra,
    netSalary: netSalary + bonus,
    providentFund,
    professionalTax,
    otherAllowances,
    incomeTax,
    postTaxSalary: netSalary + bonus - incomeTax,
  };
}

const Salary: React.FC = () => {
  const [grossSalary, setGrossSalary] = useState<number>(2000000);
  const [bonus, setBonus] = useState<number>(200000);
  const [basicSalaryRate, setBasicSalaryRate] = useState<number>(50);
  const [basicSalaryRateError, setBasicSalaryRateError] = useState<string>("");
  const [basicSalaryRateInput, setBasicSalaryRateInput] = useState<string>(
    basicSalaryRate.toString()
  );
  const [durationToggleIndex, setDurationToggleIndex] = useState<0 | 1>(0);
  const { width: screenWidth } = useWindowDimensions();

  const salaryData: SalaryData = useMemo(
    () =>
      calculateSalary(
        (basicSalaryRate >= 20
          ? basicSalaryRate <= 60
            ? basicSalaryRate
            : 60
          : 20) / 100,
        durationToggleIndex === 1,
        bonus,
        grossSalary
      ),
    [basicSalaryRate, bonus, grossSalary, durationToggleIndex]
  );

  const getChartData: () => [string, number][] = () => {
    const chartData: [string, number][] = [
      ["Basic Salary", salaryData.basicSalary],
      ["HRA", salaryData.hra],
      ["Other Allowances", salaryData.otherAllowances],
    ];
    if (!durationToggleIndex) chartData.push(["Bonus", bonus]);
    return chartData;
  };

  const getDeductionsData: () => [string, number][] = () => {
    return [
      ["Provident Fund", salaryData.providentFund],
      ["Professional Tax", salaryData.professionalTax],
    ];
  };

  const getAssumptions: () => string[] = () => {
    return [
      "HRA at 50% of Basic Pay considering Metro cities like Mumbai,Delhi,Kolkata and Chennai",
      "12% EPF of Basic",
      "Age is less than 60 yrs",
      "Old regime considered",
      "1.5 lakh 80C is completely utilised",
      "50% of HRA is completely utilised",
      "Calculated for FY20-21",
    ];
  };

  return (
    <CalculatorLayout direction="md:flex-col">
      <ResultLayout lgWidth="md:w-full">
        <div className="w-2/3">
          {screenWidth >= __md_breakpoint__ && (
            <DonutChart
              aspect={1}
              height="90%"
              bottom={0.05 * screenWidth}
              layoutDir="col"
              data={getChartData()
                .map((item) => ({ name: item[0], value: item[1] }))
                .concat([
                  {
                    name: "Provident Fund",
                    value: salaryData.providentFund,
                  },
                  {
                    name: "Professional Tax",
                    value: salaryData.professionalTax,
                  },
                ])}
            />
          )}
          <div className="w-max mx-auto my-2 md:my-auto">
            <Toggle
              label1="Yearly"
              label2="Monthly"
              selected={durationToggleIndex}
              setSelected={setDurationToggleIndex}
            />
          </div>
        </div>
        <div className="flex flex-col w-full px-4 pt-3 md:pt-0 md:ml-12 text-black80">
          {getChartData().map((dataItem, idx) => (
            <ChartLegend
              key={idx}
              bulletColor={pieColors[idx]}
              title={dataItem[0]}
              value={dataItem[1]}
              smDirection="flex-row"
              smMarginY="my-1"
            />
          ))}
          <div className="text-xs md:text-base text-black50">Deductions</div>
          {getDeductionsData().map((dataItem, idx) => (
            <ChartLegend
              key={idx}
              bulletColor={pieColors[idx + 3 + (durationToggleIndex ? 0 : 1)]}
              title={dataItem[0]}
              value={dataItem[1]}
              smDirection="flex-row"
              smMarginY="my-1"
            />
          ))}
          <hr className="border border-gt60 border-t-0 md:border-t md:border-gt40" />
          <div className="flex justify-between items-center text-xs md:text-sm font-medium">
            <div className="my-3">Total Deductions</div>
            <div>
              ₹
              {formatNumber(
                getDeductionsData()
                  .map((dataItem) => dataItem[1])
                  .reduce((a, b) => a + b, 0)
                  .toString()
              )}
            </div>
          </div>
          <hr className="border border-gt60 border-t-0 md:border-t md:border-gt40" />
          <div className="w-full flex justify-between items-center text-black100 font-medium">
            <div className="py-2 text-sm md:text-xl">Net Salary</div>
            <div className="text-sm md:text-lg">
              ₹{formatNumber(salaryData.netSalary.toString())}
            </div>
          </div>
          <div className="flex justify-between items-center text-xs md:text-sm font-medium">
            <div>Income Tax</div>
            <div>
              -₹
              {formatNumber(salaryData.incomeTax.toString())}
            </div>
          </div>
          <div className="flex items-center md:block mb-2 md:mb-0">
            <div className="w-full flex justify-between items-center md:mb-2 text-black100 font-medium">
              <div className="py-2 text-sm md:text-xl">Net Salary Post Tax</div>
              <div className="text-sm md:text-lg">
                ₹{formatNumber(salaryData.postTaxSalary.toString())}
              </div>
            </div>
            <Tooltip assumptions={getAssumptions()} />
          </div>
        </div>
      </ResultLayout>
      <div className="h-4" />
      <QuestionLayout>
        <MultipleNumberFieldSection
          title="Cost to Company (CTC)"
          descriptions={["Gross Salary", "Bonus"]}
          values={[grossSalary, bonus]}
          setValues={[setGrossSalary, setBonus]}
          prefix="₹"
          mins={[]}
          maxs={[]}
          totalDescription="CTC"
          breakpoint={screenWidth < __md_breakpoint__ ? "sm" : "md"}
        />
        <div className="md:mt-4 md:mb-2">
          <span className="text-red-500">*</span>
          The basic salary is calculated as
          <input
            style={{ width: "4ch" }}
            className="ml-2 text-center border-b border-gt80 focus:outline-none"
            value={basicSalaryRateInput}
            type="number"
            onInput={(e) => {
              setBasicSalaryRateError("");
              let newBasicSalaryRate = basicSalaryRate;
              if (!isNaN(parseFloat(e.currentTarget.value))) {
                newBasicSalaryRate = parseInt(e.currentTarget.value);
                setBasicSalaryRateInput(newBasicSalaryRate.toString());
              } else if (e.currentTarget.value.trim() === "") {
                setBasicSalaryRateInput(e.currentTarget.value.trim());
                newBasicSalaryRate = 0;
              }
              if (newBasicSalaryRate > 100) {
                newBasicSalaryRate = 100;
                setBasicSalaryRateError(
                  "Basic Salary Rate cannot be greater than 100."
                );
              }
              if (newBasicSalaryRate < 20) {
                newBasicSalaryRate = 20;
                setBasicSalaryRateError(
                  "Basic Salary Rate cannot be less than 20."
                );
              }
              setBasicSalaryRate(newBasicSalaryRate);
            }}
          />
          % of the CTC.
        </div>
        <div className="text-xs text-red-500 md:mb-4">
          {basicSalaryRateError}
        </div>
      </QuestionLayout>
    </CalculatorLayout>
  );
};

export default Salary;
