import React, { useEffect, useState } from "react";

interface DropDownProps {
  options: string[];
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

const DropDown: React.FC<DropDownProps> = ({ options, index, setIndex }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    const hideMenuOnClickOutside = () => {};
    document.addEventListener("click", hideMenuOnClickOutside);
    return () => document.removeEventListener("click", hideMenuOnClickOutside);
  }, []);
  return (
    <div className="relative w-max select-none">
      <div
        className="flex items-center w-full pl-2 py-2 text-base border rounded border-black100 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {options[index]}
        <img className="w-4 h-2 mx-2" src="/assets/down-arrow.png" alt="" />
      </div>
      <div className="absolute top-10 w-full">
        {isOpen
          ? options.map((option, idx) => (
              <p
                className={`bg-white text-xs px-2 py-1 border ${
                  idx === 0 ? "" : "border-t-0"
                } hover:bg-gt80 hover:text-white hover:border-gt80`}
                onClick={() => {
                  setIndex(idx);
                  setIsOpen(!isOpen);
                }}
              >
                {option}
              </p>
            ))
          : null}
      </div>
    </div>
  );
};

export default DropDown;
