import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

const chartColors = ["#767676", "#E36E64", "#B7352A", "#101112"];

interface DonutChartProps {
  width?: string | number;
  height?: string | number;
  aspect?: number;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  layoutDir?: "row" | "col";
  data: {
    name: string;
    value: number;
  }[];
}

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  width,
  height,
  aspect,
  top = 0,
  bottom = 0,
  left = 0,
  right = 0,
}) => {
  return (
    <ResponsiveContainer width={width} aspect={aspect} height={height}>
      <PieChart margin={{ left, right, top, bottom }}>
        <Pie
          animationBegin={0}
          animationDuration={750}
          isAnimationActive
          outerRadius="100%"
          innerRadius="40%"
          data={data}
          dataKey="value"
          nameKey="name"
          fill="#8884d8"
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={chartColors[index]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
