import { useEffect, useState } from "react";

export const pieColors = [
  "bg-sf60",
  "bg-gt80",
  "bg-gt60",
  "bg-ip60",
  "bg-sf40",
  "bg-gt40",
];
export const pieColorsV2 = [
  "bg-lightContentSecondary",
  "bg-lightBackgroundAccentAltPrimary",
  "bg-lightContentError",
  "bg-lightContentInteractive",
];
export const lineColors = ["bg-bl40", "bg-red20"];
export const barColors = ["bg-gt80", "bg-ip60", "bg-sf60"];
export const __select__ = "select";
export const __slider__ = "slider";
export const __interest_rate__ = 6.4;
export const __sm_breakpoint__ = 640;
export const __md_breakpoint__ = 768;

export interface legendProp {
  name: string;
  value: number;
  suffix?: string;
}

export function extractNumber(num: string): number {
  try {
    const numValue = parseFloat(num.replaceAll(",", ""));
    if (isNaN(numValue)) return 0;
    return numValue;
  } catch {
    return 0;
  }
}

export function formatNumber(num?: string): string {
  if (num) {
    if (num.trim() === "") return "";
    num = num.replaceAll(",", "");
    const isMoreThan999 =
      (num.indexOf(".") !== -1
        ? num.slice(0, num.indexOf(".")).length
        : num.length) > 3;
    if (isMoreThan999) {
      let decimalValues = "";
      if (num.indexOf(".") !== -1) {
        decimalValues = num.substring(num.indexOf("."));
        num = num.substring(0, num.indexOf("."));
      }
      let uptoHundredth = num.substring(num.length - 3);
      let aboveHundredth = num.substring(0, num.length - 3);
      if (aboveHundredth) uptoHundredth = "," + uptoHundredth;

      return (
        aboveHundredth.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        uptoHundredth +
        decimalValues
      );
    }
    return num.replace(",", "");
  }
  return "";
}
export const abbreviateNumber = (value: string) => {
  const num = parseInt(value);
  let newValue: number | string = num;
  const suffixes = ["", "T", "L", "Cr"];
  let suffixNum = 0;
  while (newValue >= 1000 && suffixNum < 3) {
    newValue /= 100;
    suffixNum++;
  }

  newValue /= 10;
  newValue = Math.round(newValue).toString();

  newValue += suffixes[suffixNum] ?? "Cr";
  return newValue;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [dimensions, setDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return dimensions;
}
