import React, { useState } from "react";
import { useMemo } from "react";
import CalculatorLayout from "../components/calculatorLayout";
import ChartLegend from "../components/chartLegend";
import FormLayout from "../components/formLayout";
import NumberFieldSection from "../components/numberFieldSection";
import ResultLayout from "../components/resultLayout";
import SliderSection from "../components/sliderSection";
import Tooltip from "../components/tooltip";
import { abbreviateNumber, barColors, formatNumber } from "../utils";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as BarTooltip,
  XAxis,
  YAxis,
} from "recharts";
import BarChartToolTip from "../components/barToolTip";
import BarLabel from "../components/barLabel";

const retirementAge = 70;
export const BarLabels = ["Total Investment", "Total Corpus"];

interface NpsData {
  maturityValue: number;
  pension: number;
  totalInvestment: number;
  totalAnnuity: number;
}

function calculateNps(
  age: number,
  investmentAmount: number,
  annuityRate: number,
  annuityPeriod: number,
  returnRate: number
): NpsData {
  const rate: number = returnRate / 1200;
  let maturityValue: number = 0;
  let numberOfYears: number = retirementAge - age;
  if (numberOfYears < 0) numberOfYears = 0;

  for (let i = 0; i < numberOfYears; i++) {
    maturityValue +=
      maturityValue * 12 * rate +
      investmentAmount * rate * 6 * 13 +
      investmentAmount * 12;
  }
  maturityValue = Math.ceil(maturityValue);

  let annuityAmout: number = Math.floor((maturityValue * annuityRate) / 100);

  let mul: number = 1;
  let duration: number = annuityPeriod * 12;
  for (let i = 0; i < duration; i++) {
    mul *= 1 + rate;
  }

  let pension: number = Math.ceil((rate * annuityAmout * mul) / (mul - 1));

  return {
    maturityValue,
    pension,
    totalInvestment: investmentAmount * 12 * numberOfYears,
    totalAnnuity: Math.ceil((maturityValue * annuityRate) / 100),
  };
}

const NpsCalculator: React.FC = () => {
  const [age, setAge] = useState<number>(24);
  const [investmentAmount, setInvestmentAmount] = useState<number>(40000);
  const [annuityRate, setAnnuityRate] = useState<number>(60);
  const [annuityPeriod, setAnnuityPeriod] = useState<number>(20);
  const [returnRate, setReturnRate] = useState<number>(12);

  const npsData = useMemo(
    () =>
      calculateNps(
        age,
        investmentAmount,
        annuityRate,
        annuityPeriod,
        returnRate
      ),
    [age, investmentAmount, annuityRate, annuityPeriod, returnRate]
  );

  const getAssumptions: () => string[] = () => {
    return [`Assuming retirement age to be ${retirementAge} years.`];
  };

  return (
    <CalculatorLayout>
      <ResultLayout>
        <div className="w-full">
          <div className="px-4 md:px-0 pt-4">
            <div className="mb-8 text-center text-base text-black100 font-medium md:underline">
              Total Corpus at Retirement
            </div>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart
                margin={{ top: 0, right: 10, left: 0, bottom: 0 }}
                data={[
                  {
                    name: "totalInvestment",
                    investment: npsData.totalInvestment,
                  },
                  {
                    name: "totalCorpus",
                    annuity: npsData.totalAnnuity,
                    maturity: npsData.maturityValue,
                  },
                ]}
              >
                <CartesianGrid />
                <XAxis tick={false} />
                <YAxis
                  tickCount={5}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={(tick) => abbreviateNumber(tick)}
                />
                <BarTooltip
                  cursor={{ fill: "#00000000" }}
                  offset={0}
                  label={"Total Corpus"}
                  content={<BarChartToolTip />}
                />
                <Bar
                  radius={[6, 6, 0, 0]}
                  dataKey="investment"
                  fill="#075955"
                />
                <Bar
                  widths={80}
                  label={
                    <BarLabel
                      fill="#fff"
                      // total={npsData.totalAnnuity + npsData.maturityValue}
                      rate={(
                        (npsData.totalAnnuity /
                          (npsData.totalAnnuity + npsData.maturityValue)) *
                        100
                      ).toFixed(1)}
                    />
                  }
                  stackId="corpus"
                  dataKey="annuity"
                  fill="#FC7A69"
                />
                <Bar
                  label={
                    <BarLabel
                      fill="#fff"
                      rate={(
                        (npsData.maturityValue /
                          (npsData.totalAnnuity + npsData.maturityValue)) *
                        100
                      ).toFixed(1)}
                    />
                  }
                  radius={[6, 6, 0, 0]}
                  stackId="corpus"
                  dataKey="maturity"
                  fill="#7B67C1"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="w-full">
            <div className="px-4 md:px-0">
              <ChartLegend
                bulletColor={barColors[0]}
                title={"Total Investment"}
                value={npsData.totalInvestment}
                smDirection="flex-row"
                smMarginY="my-1"
              />
            </div>
            <hr className="w-full my-2 md:my-4 md:bg-gt40 border border-t-0 md:border-t border-gt60 md:border-gt40" />
            <div className="px-4 md:px-0">
              <ChartLegend
                bulletColor={barColors[1]}
                title="Lump Sum Value"
                value={npsData.maturityValue}
                textSize="text-xs"
                smDirection="flex-row"
                smMarginY="my-1"
              />
              <ChartLegend
                bulletColor={barColors[2]}
                title="Annuity Value"
                value={npsData.totalAnnuity}
                textSize="text-xs"
                smDirection="flex-row"
                smMarginY="my-1"
              />
            </div>
            <hr className="w-full my-2 md:my-4 md:bg-gt40 border border-t-0 md:border-t border-gt60 md:border-gt40" />
            <div className="flex justify-between items-center px-4 md:px-0">
              <div className="py-1 text-xs font-medium md:font-normal md:text-base">
                Total Maturity
              </div>
              <div
                className={"ml-6 text-xs font-medium md:font-normal md:text-sm"}
              >
                ₹
                {formatNumber(
                  (
                    npsData.maturityValue +
                    npsData.totalAnnuity +
                    npsData.totalInvestment
                  ).toString()
                )}
              </div>
            </div>
          </div>
          <div className="pl-2 pt-2 pb-4 md:pt-0 md:pb-0 md:pl-0">
            <Tooltip assumptions={getAssumptions()} smHidden={false} />
          </div>
        </div>
      </ResultLayout>
      <FormLayout>
        <NumberFieldSection
          title="How much do you want to invest per month?"
          description="How much money do you wish to invest every month?"
          value={investmentAmount}
          setValue={setInvestmentAmount}
          prefix="₹"
        />
        <SliderSection
          title="Your Current Age"
          description="Tell us your age"
          min={18}
          max={65}
          value={age}
          setValue={setAge}
          suffix=" yrs"
        />
        <SliderSection
          title="What Is Your Expected Rate Of Return?"
          description="How much % of returns do you expect?"
          min={1}
          max={50}
          step={0.1}
          suffix="%"
          value={returnRate}
          setValue={setReturnRate}
        />
        <div className="my-1 font-medium text-black60 text-xl">Annuity</div>
        <SliderSection
          title="What Percent Do You Wish To Invest In Annuity?"
          description="What % of money do you wish to invest in annuity?"
          min={40}
          max={100}
          suffix="%"
          step={0.1}
          value={annuityRate}
          setValue={setAnnuityRate}
        />
        <SliderSection
          title="How Many Years Do You Wish To Invest?"
          description="How many years do you wish to invest in annuity?"
          min={1}
          max={50}
          suffix=" yrs"
          value={annuityPeriod}
          setValue={setAnnuityPeriod}
        />
      </FormLayout>
    </CalculatorLayout>
  );
};

export default NpsCalculator;
