import React from "react";
import { pieColors, formatNumber, legendProp } from "../utils";
import ChartLegend from "./chartLegend";
import DonutChart from "./donutChart";
import ResultLayout from "./resultLayout";
import Tooltip from "./tooltip";

interface DonutResultProps {
  data: { name: string; value: number }[];
  extendedLegends?: legendProp[];
  chartWidth?: string | number;
  chartHeight?: string | number;
  chartAspect?: number;
  totalData?: legendProp;
  assumptions?: string[];
}

const DonutResult: React.FC<DonutResultProps> = ({
  assumptions,
  data,
  extendedLegends,
  totalData,
  chartWidth,
  chartHeight,
  chartAspect,
}) => {
  return (
    <ResultLayout>
      <div className="flex flex-row justify-between items-center w-full h-full pt-4 px-4 md:flex-col md:justify-center md:pt-0 md:px-0">
        <div className="w-1/2 h-40 md:w-full md:h-full">
          <DonutChart
            width={chartWidth}
            height={chartHeight}
            aspect={chartAspect}
            data={data}
          />
        </div>
        <div className="flex flex-col text-black100 md:w-full md:text-black80">
          <div>
            {data.map((legend, idx) => (
              <ChartLegend
                key={idx}
                title={legend.name}
                value={legend.value}
                bulletColor={pieColors[idx]}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="w-full mt-4 pr-4 md:mt-0">
        {extendedLegends?.map((legend, idx) => (
          <ChartLegend
            key={idx}
            title={legend.name}
            value={legend.value}
            suffix={legend.suffix}
            bulletColor=""
            smDirection="flex-row"
            smMarginY="my-1"
          />
        ))}
      </div>
      {totalData && (
        <hr className="w-full mt-4 border border-gt20 md:mt-0 md:border-gt40" />
      )}
      <div className="flex flex-row items-center w-full pt-2 px-6 mb-2 md:flex-col md:p-0">
        {totalData && (
          <>
            <div className="w-full flex justify-between items-center text-black80 font-medium md:text-black100">
              <div className="py-2 text-base md:text-xl">{totalData.name}</div>
              <div className="text-base md:text-lg">
                ₹{formatNumber(totalData.value.toString())}
              </div>
            </div>
          </>
        )}
        {assumptions && <Tooltip assumptions={assumptions} />}
      </div>
    </ResultLayout>
  );
};

export default DonutResult;
