import React, { useState } from "react";
import NumberField from "./numberField";
import QuestionTitle from "./questionTitle";

interface MultipleNumberFieldSectionProps {
  title: string;
  descriptions: string[];
  totalDescription: string;
  values: number[];
  mins: (number | undefined)[];
  maxs: (number | undefined)[];
  optional?: boolean[];
  showTotal?: boolean;
  prefix?: string;
  breakpoint?: "sm" | "md";
  setValues: React.Dispatch<React.SetStateAction<number>>[];
}

const MultipleNumberFieldSection: React.FC<MultipleNumberFieldSectionProps> = ({
  breakpoint = "md",
  title,
  descriptions,
  totalDescription,
  values,
  optional,
  mins,
  maxs,
  showTotal = true,
  prefix,
  setValues,
}) => {
  const [errors, setErrors] = useState<("min" | "max" | "empty" | undefined)[]>(
    values.map(() => undefined)
  );

  if (breakpoint === "md")
    return (
      <div className="py-4">
        <QuestionTitle
          error={errors.some((item) => item !== undefined) ? true : false}
          title={title}
        />
        <div className="flex justify-between items-start">
          {values.map((value, idx) => (
            <React.Fragment key={idx}>
              <div className="flex-1">
                <div className="mb-4 text-sm text-black60">
                  {descriptions[idx]}
                </div>
                <NumberField
                  min={mins[idx]}
                  max={maxs[idx]}
                  value={value}
                  setValue={setValues[idx]}
                  optional={optional?.[idx]}
                  prefix={prefix}
                  errorMessage={
                    errors[idx] === "min"
                      ? `${descriptions[idx]} cannot be less than ${mins[idx]}.`
                      : errors[idx] === "max"
                      ? `${descriptions[idx]} cannot be more than ${maxs[idx]}.`
                      : errors[idx] === "empty"
                      ? `${descriptions[idx]} cannot be empty.`
                      : undefined
                  }
                  setError={(newError: "min" | "max" | "empty" | undefined) => {
                    const newErrors = [...errors];
                    newErrors[idx] = newError;
                    setErrors(newErrors);
                  }}
                />
              </div>
              {idx < values.length - 1 && (
                <div
                  className={
                    "ml-4 text-base text-black60 leading-10" +
                    (showTotal ? "" : " invisible")
                  }
                >
                  +
                </div>
              )}
            </React.Fragment>
          ))}

          <div
            className={
              "ml-4 text-base text-black60 leading-10" +
              (showTotal ? "" : " invisible")
            }
          >
            =
          </div>
          <div className={"flex-1 pl-4" + (showTotal ? "" : " invisible")}>
            <div className="mb-4 text-sm text-black60">{totalDescription}</div>
            <div className="flex items-center pl-2 py-2">
              <span className="mr-3 text-black40 text-lg">{prefix}</span>
              <div className="w-full text-black100 text-base focus:outline-none">
                {values.reduce((a, b) => a + b, 0)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="py-4">
        <QuestionTitle
          error={errors.some((e) => e !== undefined) ? true : false}
          title={title}
        />
        <div>
          {values.map((value, idx) => (
            <React.Fragment key={idx}>
              <div className="flex-1 mb-3">
                <div className="mb-2 text-2xs text-black60 md:text-sm">
                  {idx < values.length && idx > 0 && (
                    <span className="mr-1 text-black80">+</span>
                  )}
                  {descriptions[idx]}
                </div>
                <NumberField
                  optional={false}
                  min={mins[idx]}
                  max={maxs[idx]}
                  value={value}
                  setValue={setValues[idx]}
                  prefix={prefix}
                  errorMessage={
                    errors[idx] === "min"
                      ? `${descriptions[idx]} cannot be less than ${mins[idx]}.`
                      : errors[idx] === "max"
                      ? `${descriptions[idx]} cannot be more than ${maxs[idx]}.`
                      : errors[idx] === "empty"
                      ? `${descriptions[idx]} cannot be empty.`
                      : undefined
                  }
                  setError={(newError: "max" | "min" | "empty" | undefined) => {
                    const newErrors = [...errors];
                    newErrors[idx] = newError;
                    setErrors(newErrors);
                  }}
                />
              </div>
            </React.Fragment>
          ))}
          <span className="mr-1 text-2xs text-black80 md:text-sm">=</span>
          <span className="flex-1">
            <span className="mb-4 text-2xs text-black60 md:text-sm">
              {totalDescription}
            </span>
            <div className="flex items-center pl-2 py-2">
              <span className="mr-3 text-black40 text-lg">{prefix}</span>
              <div className="w-full text-black100 text-base focus:outline-none">
                {values.reduce((a, b) => a + b, 0)}
              </div>
            </div>
          </span>
        </div>
      </div>
    );
};

export default MultipleNumberFieldSection;
