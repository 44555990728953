import React from "react";
import QuestionTitle from "./questionTitle";
import Select from "./select";

interface SelectSectionProps {
  title: string;
  description?: string;
  index: number;
  setIndex:
    | React.Dispatch<React.SetStateAction<number>>
    | ((newIndex: number) => void);
  options: string[];
}

const SelectSection: React.FC<SelectSectionProps> = ({
  description,
  title,
  index,
  options,
  setIndex,
}) => {
  return (
    <div className="pt-4 md:py-4">
      <QuestionTitle title={title} />
      {description && (
        <div
          className={
            "mb-2 text-2xs text-black40 md:mb-3 md:text-sm md:text-black80"
          }
        >
          {description}
        </div>
      )}
      <Select options={options} setIndex={setIndex} index={index} />
    </div>
  );
};

export default SelectSection;
