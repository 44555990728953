import React from "react";
import Collapsible from "../../components/v2/collapsible";
import { formatNumber } from "../../utils";

interface Installment {
  installmentNumber: number;
  installmentMonth: string;
  installmentYear: string;
  installmentMonthNumber: number;
  openingBalance: string;
  principal: string;
  monthlyInterest: string;
  monthlyPayment: string;
  partPaymentMade: string;
  monthlyPaymentWithPartPayment: string;
  remainingLoanAmount: string;
  loanPaid: string;
}

interface HomeLoanEmiData {
  principal: number;
  totalinterest: number;
  monthlyEmi: number;
  totalPartPayment: number;
  emiStartDate: string;
  monthlyPayment: number;
  totalAmount: number;
  amortizationSchedule: Installment[][];
  processingFees: number;
  prepaymentFrequency: number;
}

interface ScheduleTableProps {
  homeLoanEmiData: HomeLoanEmiData;
  prepaymentFrequency: number;
  prepaymentDate: string;
}

const ScheduleTable: React.FC<ScheduleTableProps> = ({
  homeLoanEmiData,
  prepaymentFrequency,
  prepaymentDate,
}) => {
  return (
    <div>
      <div
        className="md:opacity-100 md:h-full"
        style={{ width: "100%", margin: "auto" }}
      >
        <table
          className="rounded-corners table-auto md:table hidden text-center w-full border border-separate border-spacing-0 border-lightBorderSecondary rounded-xl"
          style={{ borderSpacing: 0 }}
        >
          <thead>
            <tr>
              <th
                colSpan={1}
                className="text-sm text-lightContentInteractive px-4 py-4 border-b border-r border-lightBorderSecondary"
                style={{ fontWeight: 500 }}
              >
                Year
              </th>
              <th
                colSpan={1}
                className="text-sm text-lightContentInteractive px-4 py-4 border-b border-r border-lightBorderSecondary"
                style={{ textAlign: "left", fontWeight: 500 }}
              >
                Monthly EMI Breakup
              </th>
              <th
                colSpan={1}
                className="text-sm  px-4 py-4 text-lightContentSecondary border-b border-r-0 border-lightBorderSecondary"
                style={{ fontWeight: 500 }}
              >
                Principal <br></br>(A)
              </th>
              <th
                colSpan={1}
                className="text-sm  px-4 py-4 text-lightBackgroundAccentAltPrimary border-b border-r-0 border-lightBorderSecondary"
                style={{ fontWeight: 500 }}
              >
                Interest<br></br>(B)
              </th>
              {homeLoanEmiData.totalPartPayment > 0 && (
                <th
                  colSpan={1}
                  className="text-sm  px-4 py-4 text-lightContentAccent border-b border-r-0 border-lightBorderSecondary"
                  style={{ fontWeight: 500 }}
                >
                  Pre-payment<br></br>(C)
                </th>
              )}
              <th
                colSpan={1}
                className="text-sm text-lightContentInteractive px-4 py-4  border-b border-r-0 border-lightBorderSecondary"
                style={{ fontWeight: 500 }}
              >
                Total Payment<br></br>(A+B+C)
              </th>
              <th
                colSpan={1}
                className="text-sm text-lightContentInteractive px-4 py-4  border-b border-r-0 border-lightBorderSecondary"
                style={{ fontWeight: 500 }}
              >
                Loan paid<br></br>to date
              </th>
            </tr>
          </thead>
          <tbody>
            {homeLoanEmiData.amortizationSchedule &&
              homeLoanEmiData.amortizationSchedule.map(
                (row: Installment[], index: number) => {
                  const principalAmounts = row.map((x: Installment) =>
                    Number(x.principal.replace(/,/g, ""))
                  );

                  const totalPrincipalAmounts = principalAmounts.reduce(
                    (acc: number, curr: number) => acc + curr
                  );

                  const interestAmounts = row.map((x: Installment) =>
                    Number(x.monthlyInterest.replace(/,/g, ""))
                  );

                  const totalInterestAmounts = interestAmounts.reduce(
                    (acc: number, curr: number) => acc + curr
                  );

                  const prepaymentAmounts = row.map((x: Installment) =>
                    Number(x.partPaymentMade.replace(/,/g, ""))
                  );

                  const totalPrepaymentAmounts = prepaymentAmounts.reduce(
                    (acc: number, curr: number) => acc + curr
                  );

                  const totalAmount = row.map((x: Installment) =>
                    Number(x.monthlyPaymentWithPartPayment.replace(/,/g, ""))
                  );

                  const totalAmounts = totalAmount.reduce(
                    (acc: number, curr: number) => acc + curr
                  );

                  const loanPaid = row.map((x: Installment) =>
                    Number(x.loanPaid.replace(/,/g, ""))
                  );

                  const avarageLoanPaid = loanPaid[loanPaid.length - 1];

                  return (
                    <tr key={index} className="last:border-b-0">
                      <td
                        className={`px-4 py-4 text-lightContentInteractive border-r border-lightBorderSecondary ${
                          index ===
                          homeLoanEmiData.amortizationSchedule.length - 1
                            ? "border-b-0"
                            : "border-b"
                        }`}
                      >
                        {row[0].installmentYear}
                      </td>
                      <td
                        className={`px-4 py-4 border-r border-lightBorderSecondary ${
                          index ===
                          homeLoanEmiData.amortizationSchedule.length - 1
                            ? "border-b-0"
                            : "border-b"
                        }`}
                        style={{ paddingBottom: "25px", width: "30%" }}
                      >
                        <div
                          className="monthly-info-wrapper flex items-center gap-2.5"
                          style={{ flexBasis: "content" }}
                        >
                          {row.map((el: Installment) => {
                            const totalEmiAmount =
                              Number(el.principal.replace(/,/g, "")) +
                              Number(el.monthlyInterest.replace(/,/g, "")) +
                              (Number(el.partPaymentMade) > 0
                                ? Number(el.partPaymentMade.replace(/,/g, ""))
                                : 0);

                            // const el_principal = Number((el.principal).replace(/,/g, ''))
                            const el_principal = Number(
                              el.principal.replace(/,/g, "")
                            );
                            const principalPercentage = Math.round(
                              (el_principal / totalEmiAmount) * 100
                            );

                            const el_interest = Number(
                              el.monthlyInterest.replace(/,/g, "")
                            );
                            const interestPercentage = Math.round(
                              (el_interest / totalEmiAmount) * 100
                            );

                            const el_partpayment = Number(
                              el.partPaymentMade.replace(/,/g, "")
                            );
                            const partpaymentPercentage = Math.round(
                              (el_partpayment / totalEmiAmount) * 100
                            );

                            return el.openingBalance !== "0" ? (
                              <div
                                key={el.installmentMonthNumber}
                                className="monthly-info-icon flex h-5 w-5 rounded border border-lightContentSecondary relative justify-center"
                              >
                                <div
                                  style={{
                                    background: "#101112",
                                    height: "100%",
                                    width: `${partpaymentPercentage}%`,
                                  }}
                                ></div>
                                <div
                                  style={{
                                    background: "#767676",
                                    height: "100%",
                                    width: `${interestPercentage}%`,
                                  }}
                                ></div>
                                <div
                                  style={{
                                    background: "#E36E64",
                                    height: "100%",
                                    width: `${principalPercentage}%`,
                                  }}
                                ></div>
                                <p
                                  className="text-xs mt-5"
                                  style={{ position: "absolute", top: 0 }}
                                >
                                  {el.installmentMonth}
                                </p>
                                <div
                                  className="loan-breakup px-4 py-4 absolute hidden rounded z-10 top-10 bg-darkBackgroundPrimary"
                                  style={{
                                    height:
                                      Number(
                                        el.partPaymentMade.replace(/,/g, "")
                                      ) > 0
                                        ? "170px"
                                        : "142px",
                                    width: "220px",
                                    background: "#2b2d33",
                                  }}
                                >
                                  <div
                                    className="notch absolute border-8 border-x-8 border-t-0 border-b-8 border-transparent"
                                    style={{
                                      top: "-10px",
                                      left: "100px",
                                      borderBottom: "10px solid #2b2d33",
                                    }}
                                  ></div>
                                  <div
                                    className="absolute"
                                    style={{ width: "82%" }}
                                  >
                                    <p
                                      className="text-sm"
                                      style={{
                                        color: "#fff",
                                        textAlign: "left",
                                      }}
                                    >
                                      {el.installmentMonth} {el.installmentYear}
                                    </p>
                                    <div
                                      style={{
                                        borderBottom:
                                          "dashed 1px rgba(255,255, 255, 0.24)",
                                        marginTop: 6,
                                      }}
                                    ></div>
                                    <div
                                      className="flex mt-2"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span className="text-sm text-lightContentSecondary mb-1">
                                        {" "}
                                        Principal:{" "}
                                      </span>
                                      <span className="text-sm text-lightContentSecondary mb-1">
                                        {formatNumber(el.principal.toString())}
                                      </span>
                                    </div>
                                    <div
                                      className="flex mt-1"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span className="text-sm text-lightBackgroundAccentAltPrimary mb-1">
                                        {" "}
                                        Interest:{" "}
                                      </span>
                                      <span className="text-sm text-lightBackgroundAccentAltPrimary mb-1">
                                        {formatNumber(
                                          el.monthlyInterest.toString()
                                        )}
                                      </span>
                                    </div>
                                    {Number(
                                      el.partPaymentMade.replace(/,/g, "")
                                    ) > 0 && (
                                      <div
                                        className="flex mt-1"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <span
                                          className="text-sm text-lightBackgroundAccentAltPrimary mb-1"
                                          style={{ color: "#fff" }}
                                        >
                                          {" "}
                                          Pre-Payment:{" "}
                                        </span>
                                        <span
                                          className="text-sm text-lightBackgroundAccentAltPrimary mb-1"
                                          style={{ color: "#fff" }}
                                        >
                                          {el.partPaymentMade}
                                        </span>
                                      </div>
                                    )}

                                    <div
                                      className="flex mt-1"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span
                                        className="text-sm"
                                        style={{ color: "#fff" }}
                                      >
                                        {" "}
                                        EMI amount:{" "}
                                      </span>
                                      <span
                                        className="text-sm"
                                        style={{ color: "#fff" }}
                                      >
                                        {formatNumber(
                                          (
                                            Number(
                                              el.principal.replace(/,/g, "")
                                            ) +
                                            Number(
                                              el.monthlyInterest.replace(
                                                /,/g,
                                                ""
                                              )
                                            ) +
                                            (Number(el.partPaymentMade) > 0
                                              ? Number(
                                                  el.partPaymentMade.replace(
                                                    /,/g,
                                                    ""
                                                  )
                                                )
                                              : 0)
                                          ).toString()
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="monthly-info-icon"></div>
                            );
                          })}
                        </div>
                      </td>
                      <td
                        className={`px-4 py-4 text-lightContentSecondary border-r-0 border-lightBorderSecondary ${
                          index ===
                          homeLoanEmiData.amortizationSchedule.length - 1
                            ? "border-b-0"
                            : "border-b"
                        }`}
                      >
                        {formatNumber(totalPrincipalAmounts.toString())}
                      </td>
                      <td
                        className={`px-4 py-4 text-lightBackgroundAccentAltPrimary border-r-0 border-lightBorderSecondary ${
                          index ===
                          homeLoanEmiData.amortizationSchedule.length - 1
                            ? "border-b-0"
                            : "border-b"
                        }`}
                      >
                        {formatNumber(totalInterestAmounts.toString())}
                      </td>
                      {prepaymentFrequency &&
                      prepaymentFrequency == 2 &&
                      Number(row[0].installmentYear) ===
                        new Date(prepaymentDate).getFullYear() &&
                      homeLoanEmiData.totalPartPayment > 0 ? (
                        <td
                          className={`px-4 py-4 text-lightContentAccent border-r-0 border-lightBorderSecondary ${
                            index ===
                            homeLoanEmiData.amortizationSchedule.length - 1
                              ? "border-b-0"
                              : "border-b"
                          }`}
                        >
                          {formatNumber(
                            homeLoanEmiData.totalPartPayment.toString()
                          )}
                        </td>
                      ) : homeLoanEmiData.totalPartPayment > 0 ? (
                        <td
                          className={`px-4 py-4 text-lightContentAccent border-r-0 border-lightBorderSecondary ${
                            index ===
                            homeLoanEmiData.amortizationSchedule.length - 1
                              ? "border-b-0"
                              : "border-b"
                          }`}
                        >
                          {formatNumber(totalPrepaymentAmounts.toString())}
                        </td>
                      ) : null}
                      <td
                        className={`px-4 py-4 text-lightContentInteractive border-r-0 border-lightBorderSecondary ${
                          index ===
                          homeLoanEmiData.amortizationSchedule.length - 1
                            ? "border-b-0"
                            : "border-b"
                        }`}
                      >
                        {formatNumber(totalAmounts.toString())}
                      </td>

                      <td
                        className={`px-4 py-4 text-lightContentInteractive border-r-0 border-lightBorderSecondary ${
                          index ===
                          homeLoanEmiData.amortizationSchedule.length - 1
                            ? "border-b-0"
                            : "border-b"
                        }`}
                      >
                        {avarageLoanPaid}%
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
      </div>
      <div
        className="md:h-0 md:w-0"
        style={{ width: "100%", overflowX: "auto" }}
      >
        <div
          className="flex text-center"
          style={{
            border: "1px solid rgb(43, 45, 51, 0.12)",
            width: "200%",
            borderLeft: "none",
          }}
        >
          <div
            className={`text-sm text-lightContentInteractive px-4 py-4 sticky left-0
             ${homeLoanEmiData.totalPartPayment > 0 ? "w-1/6" : "w-1/5"}`}
            style={{
              background: "#fff",
              borderLeft: "1px solid rgb(43, 45, 51, 0.12)",
              borderRight: "1px solid rgb(43, 45, 51, 0.12)",
            }}
          >
            Year
          </div>
          <div
            className={`text-sm text-lightContentSecondary px-4 py-4
            ${homeLoanEmiData.totalPartPayment > 0 ? "w-1/6" : "w-1/5"}`}
          >
            Principal <br></br>(A)
          </div>
          <div
            className={`text-sm text-lightBackgroundAccentAltPrimary px-4 py-4
            ${homeLoanEmiData.totalPartPayment > 0 ? "w-1/6" : "w-1/5"}`}
          >
            Interest<br></br>(B)
          </div>
          {homeLoanEmiData.totalPartPayment > 0 && (
            <div
              className={`text-sm text-lightContentAccent px-4 py-4
            ${homeLoanEmiData.totalPartPayment > 0 ? "w-1/6" : "w-1/5"}`}
            >
              Pre-payment<br></br>(C)
            </div>
          )}
          <div
            className={`text-sm text-lightContentInteractive px-4 py-4
            ${homeLoanEmiData.totalPartPayment > 0 ? "w-1/6" : "w-1/5"}`}
          >
            Total Payment<br></br>(A+B+C)
          </div>
          <div
            className={`text-sm text-lightContentInteractive px-4 py-4
            ${homeLoanEmiData.totalPartPayment > 0 ? "w-1/6" : "w-1/5"}`}
          >
            Loan paid<br></br>to date
          </div>
        </div>
        <div style={{ width: "200%" }}>
          {homeLoanEmiData.amortizationSchedule &&
            homeLoanEmiData.amortizationSchedule.map(
              (row: Installment[], index: number) => {
                const principalAmounts = row.map((x: Installment) =>
                  Number(x.principal.replace(/,/g, ""))
                );

                const totalPrincipalAmounts = principalAmounts.reduce(
                  (acc: number, curr: number) => acc + curr
                );

                const interestAmounts = row.map((x: Installment) =>
                  Number(x.monthlyInterest.replace(/,/g, ""))
                );

                const totalInterestAmounts = interestAmounts.reduce(
                  (acc: number, curr: number) => acc + curr
                );

                const prepaymentAmounts = row.map((x: Installment) =>
                  Number(x.partPaymentMade.replace(/,/g, ""))
                );

                const totalPrepaymentAmounts = prepaymentAmounts.reduce(
                  (acc: number, curr: number) => acc + curr
                );

                const totalAmount = row.map((x: Installment) =>
                  Number(x.monthlyPaymentWithPartPayment.replace(/,/g, ""))
                );

                const totalAmounts = totalAmount.reduce(
                  (acc: number, curr: number) => acc + curr
                );

                const loanPaid = row.map((x: Installment) =>
                  Number(x.loanPaid.replace(/,/g, ""))
                );

                const avarageLoanPaid = loanPaid[loanPaid.length - 1];

                return (
                  <div key={index}>
                    <Collapsible
                      title={
                        <div className="flex text-center">
                          <div
                            className={`flex text-sm text-lightContentInteractive px-4 py-4 sticky left-0
                            ${
                              homeLoanEmiData.totalPartPayment > 0
                                ? "w-1/6"
                                : "w-1/5"
                            }`}
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              borderLeft: "solid 1px rgb(43, 45, 51, 0.12)",
                              borderRight: "1px solid rgb(43, 45, 51, 0.12)",
                              background: "#fff",
                            }}
                          >
                            <div className="mr-2">
                              <img
                                src="/assets/down-chevron.svg"
                                height={12}
                                width={12}
                                alt="down-chevron"
                              />
                            </div>
                            <span>{row[0].installmentYear}</span>
                          </div>
                          <div
                            className={`text-sm text-lightContentSecondary px-4 py-4
                            ${
                              homeLoanEmiData.totalPartPayment > 0
                                ? "w-1/6"
                                : "w-1/5"
                            }`}
                          >
                            {formatNumber(totalPrincipalAmounts.toString())}
                          </div>
                          <div
                            className={`text-sm text-lightBackgroundAccentAltPrimary px-4 py-4
                            ${
                              homeLoanEmiData.totalPartPayment > 0
                                ? "w-1/6"
                                : "w-1/5"
                            }`}
                          >
                            {formatNumber(totalInterestAmounts.toString())}
                          </div>
                          {prepaymentFrequency == 2 &&
                          Number(row[0].installmentYear) ==
                            new Date(prepaymentDate).getFullYear() &&
                          homeLoanEmiData.totalPartPayment > 0 ? (
                            <div
                              className={`text-sm text-lightContentAccent px-4 py-4
                              ${
                                homeLoanEmiData.totalPartPayment > 0
                                  ? "w-1/6"
                                  : "w-1/5"
                              }`}
                            >
                              {formatNumber(
                                homeLoanEmiData.totalPartPayment.toString()
                              )}
                            </div>
                          ) : homeLoanEmiData.totalPartPayment > 0 ? (
                            <div
                              className={`text-sm text-lightContentAccent px-4 py-4
                              ${
                                homeLoanEmiData.totalPartPayment > 0
                                  ? "w-1/6"
                                  : "w-1/5"
                              }`}
                            >
                              {formatNumber(totalPrepaymentAmounts.toString())}
                            </div>
                          ) : null}
                          <div
                            className={`text-sm text-lightContentInteractive px-4 py-4
                            ${
                              homeLoanEmiData.totalPartPayment > 0
                                ? "w-1/6"
                                : "w-1/5"
                            }`}
                          >
                            {formatNumber(totalAmounts.toString())}
                          </div>
                          <div
                            className={`text-sm text-lightContentInteractive px-4 py-4
                            ${
                              homeLoanEmiData.totalPartPayment > 0
                                ? "w-1/6"
                                : "w-1/5"
                            }`}
                            style={{
                              borderRight: "solid 1px rgb(43, 45, 51, 0.12)",
                            }}
                          >
                            {avarageLoanPaid}%
                          </div>
                        </div>
                      }
                    >
                      {row.map((el: Installment) => {
                        return (
                          <div
                            key={el.installmentMonthNumber}
                            className="flex text-center"
                            style={{
                              borderBottom: "solid 1px rgb(43, 45, 51, 0.12)",
                              background: "#F5F3EF",
                            }}
                          >
                            <div
                              className={`text-sm text-lightContentInteractive px-4 py-4 sticky left-0
                              ${
                                homeLoanEmiData.totalPartPayment > 0
                                  ? "w-1/6"
                                  : "w-1/5"
                              }`}
                              style={{
                                borderLeft: "solid 1px rgb(43, 45, 51, 0.12)",
                                borderRight: "1px solid rgb(43, 45, 51, 0.12)",
                                background: "#F5F3EF",
                              }}
                            >
                              {el.installmentMonth}
                            </div>
                            <div
                              className={`text-sm text-lightContentSecondary px-4 py-4
                              ${
                                homeLoanEmiData.totalPartPayment > 0
                                  ? "w-1/6"
                                  : "w-1/5"
                              }`}
                            >
                              {formatNumber(el.principal.toString())}
                            </div>
                            <div
                              className={`text-sm text-lightBackgroundAccentAltPrimary px-4 py-4
                              ${
                                homeLoanEmiData.totalPartPayment > 0
                                  ? "w-1/6"
                                  : "w-1/5"
                              }`}
                            >
                              {formatNumber(el.monthlyInterest.toString())}
                            </div>
                            {homeLoanEmiData.totalPartPayment > 0 && (
                              <div
                                className={`text-sm text-lightContentAccent px-4 py-4
                                ${
                                  homeLoanEmiData.totalPartPayment > 0
                                    ? "w-1/6"
                                    : "w-1/5"
                                }`}
                              >
                                {el.partPaymentMade}
                              </div>
                            )}

                            <div
                              className={`text-sm text-lightContentInteractive px-4 py-4
                              ${
                                homeLoanEmiData.totalPartPayment > 0
                                  ? "w-1/6"
                                  : "w-1/5"
                              }`}
                            >
                              {el.monthlyPaymentWithPartPayment}
                            </div>
                            <div
                              className={
                                homeLoanEmiData.totalPartPayment > 0
                                  ? "text-sm text-lightContentInteractive px-4 py-2 w-1/6"
                                  : "text-sm text-lightContentInteractive px-4 py-2 w-1/5"
                              }
                            >
                              {el.loanPaid}%
                            </div>
                          </div>
                        );
                      })}
                    </Collapsible>
                  </div>
                );
              }
            )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleTable;
