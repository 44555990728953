import React from "react";

interface ToggleProps {
  label1: string;
  label2: string;
  selected: 0 | 1;
  setSelected: React.Dispatch<React.SetStateAction<0 | 1>>;
}

const Toggle: React.FC<ToggleProps> = ({
  label1,
  label2,
  selected,
  setSelected,
}) => {
  return (
    <div
      style={{ padding: "4px" }}
      className="flex bg-gt60 w-max rounded-full text-gt80 text-sm font-bold md:bg-gt40 md:font-medium"
    >
      <div
        onClick={() => setSelected(0)}
        className={
          "px-5 py-1 cursor-pointer rounded-full " +
          (selected === 0 ? "bg-gt20" : "text-gt20 md:text-gt80")
        }
      >
        {label1}
      </div>
      <div
        onClick={() => setSelected(1)}
        className={
          "px-5 py-1 rounded-full cursor-pointer " +
          (selected === 1 ? "bg-gt20" : "text-gt20 md:text-gt80")
        }
      >
        {label2}
      </div>
    </div>
  );
};

export default Toggle;
