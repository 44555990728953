import React from "react";

const FooterCTA: React.FC = () => {
  return (
    <div className="mb-6 md:px-3 md:my-6">
      <div className="flex flex-col justify-between items-center px-8 md:pl-8 md:pr-4 py-4 bg-sf20 rounded-t-none rounded-lg md:flex-row md:rounded-t-lg">
        <div className="mb-4 text-sm md:mb-0 md:text-lg">
          Get Jupiter For Your New Age Banking Needs
        </div>
        <a
          href="https://jupiter.money/early-access-android/"
          target="_blank"
          className="w-full px-6 py-4 bg-sf60 text-center text-white font-bold text-base rounded-lg focus:outline-none md:w-auto md:py-3 md:font-medium md:text-lg"
        >
          Download Jupiter
        </a>
      </div>
    </div>
  );
};

export default FooterCTA;
