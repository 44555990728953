import React from "react";

interface ResultLayoutProps {
  lgWidth?: "md:w-1/3" | "md:w-full";
}

const ResultLayout: React.FC<ResultLayoutProps> = ({ children, lgWidth }) => {
  return (
    <div className="top-0 z-50 p-1 bg-white rounded-lg rounded-b-none w-full md:static md:p-0 md:border-none ">
      <div
        className={
          "flex items-center justify-between w-full h-full rounded-lg " +
          (lgWidth !== "md:w-full"
            ? "flex-col"
            : "flex-col-reverse md:flex-row md:items-stretch")
        }
      >
        {children}
      </div>
    </div>
  );
};

export default ResultLayout;
