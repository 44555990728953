import React from "react";

interface ResultLayoutProps {
  lgWidth?: "md:w-1/3" | "md:w-full";
}

const ResultLayout: React.FC<ResultLayoutProps> = ({ children, lgWidth }) => {
  return (
    <div
      className={
        "sticky top-0 z-50 p-3 mr-3 bg-white rounded-lg rounded-b-none border border-gray100 w-full md:static md:p-0 md:border-none " +
        (lgWidth ? lgWidth : "md:w-2/5 lg:w-1/3")
      }
    >
      <div className="pb-3 text-center text-lg text-gt60 md:hidden ">
        Calculate Instantly
      </div>
      <div
        className={
          "flex items-center justify-between w-full h-full bg-gt40 rounded-lg md:p-4 md:bg-gt20 " +
          (lgWidth !== "md:w-full"
            ? "flex-col"
            : "flex-col-reverse md:flex-row md:items-stretch")
        }
      >
        {children}
      </div>
    </div>
  );
};

export default ResultLayout;
