import React, { useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

const chartColors = [
  "#FC7A69",
  "#075955",
  "#27ABA7",
  "#7B67C1",
  "#FFBFB5",
  "#A3E3E3",
];

interface DonutChartProps {
  width?: string | number;
  height?: string | number;
  aspect?: number;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  layoutDir?: "row" | "col";
  data: {
    name: string;
    value: number;
  }[];
}

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}) => {
  const RADIAN = Math.PI / 180;

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {percent > 0.02 ? `${(percent * 100).toFixed(0)}%` : ""}
    </text>
  );
};

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  width,
  height,
  aspect,
  top = 0,
  bottom = 0,
  left = 0,
  right = 0,
}) => {
  const [isAnimationActive, setIsAnimationActive] = useState<boolean>(true);

  return (
    <ResponsiveContainer width={width} aspect={aspect} height={height}>
      <PieChart margin={{ left, right, top, bottom }}>
        <Pie
          animationBegin={0}
          animationDuration={750}
          isAnimationActive={isAnimationActive}
          onAnimationEnd={() => {
            setIsAnimationActive(false);
          }}
          label={renderCustomizedLabel}
          outerRadius="100%"
          innerRadius="50%"
          data={data}
          dataKey="value"
          nameKey="name"
          fill="#8884d8"
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={chartColors[index]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
