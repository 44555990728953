import React from "react";
import { BarLabels } from "../pages/nps";
import { formatNumber } from "../utils";

interface BarChartToolTipProps {
  active?: boolean;
  payload?: any;
  label?: any;
}

const BarChartToolTip: React.FC<BarChartToolTipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length)
    return (
      <div className="relative">
        <div
          style={{ transform: "rotate(45deg)" }}
          className={
            "w-4 h-4 absolute top-4 bg-black rounded " +
            (label === 0 ? "-left-1" : "-right-1")
          }
        />
        <div className="px-4 py-2 bg-black text-white font-medium rounded">
          <div>{BarLabels[label]}</div>
          <div>
            ₹{" "}
            {formatNumber(
              (
                payload[0].value + (payload[1] ? payload[1].value : 0)
              ).toString()
            )}
          </div>
        </div>
      </div>
    );
  return null;
};

export default BarChartToolTip;
