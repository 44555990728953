import React, { useMemo } from "react";
import CalculatorLayout from "../components/calculatorLayout";
import DonutResult from "../components/donutResult";
import FormLayout from "../components/formLayout";
import NumberFieldSection from "../components/numberFieldSection";
import SelectSection from "../components/selectSection";

interface HraData {
  hraChargeable: number;
  hraExemption: number;
}

function calcalateHra(
  salary: number,
  hra: number,
  rent: number,
  metro: boolean
): HraData {
  const firstParameter = hra;
  const secondParameter = (metro ? 0.5 : 0.4) * salary;
  const thirdParameter = rent - 0.1 * salary;
  let hraExemption = Math.min(firstParameter, secondParameter, thirdParameter);
  if (hraExemption < 0) hraExemption = 0;
  const hraChargeable = hra - hraExemption;
  return {
    hraChargeable,
    hraExemption,
  };
}

const HraCalculator: React.FC = () => {
  const [salary, setSalary] = React.useState(800000);
  const [hra, setHra] = React.useState(500000);
  const [rent, setRent] = React.useState(200000);
  const [metro, setMetro] = React.useState(0);

  const hraData = useMemo(
    () => calcalateHra(salary, hra, rent, !metro),
    [salary, hra, rent, metro]
  );

  return (
    <CalculatorLayout>
      <DonutResult
        data={[
          { name: "HRA Exemption", value: hraData.hraExemption },
          { name: "HRA Chargeable", value: hraData.hraChargeable },
        ]}
        totalData={{ name: "Total HRA", value: hra }}
        assumptions={[
          "For Metro cities like Mumbai, Delhi, Kolkata and Chennai, HRA calculated at 50% of Basic salary + Dearness allowance",
          "For non-metro cities, HRA calculated at 40% of Basic salary + Dearness allowance",
        ]}
      />
      <FormLayout>
        <NumberFieldSection
          setValue={setSalary}
          title="Yearly Salary"
          description="What is the your Basic Yearly Salary including Dearness Allowance?"
          value={salary}
          prefix="₹"
        />
        <NumberFieldSection
          setValue={setHra}
          title="What is your Yearly HRA?"
          value={hra}
          prefix="₹"
        />
        <NumberFieldSection
          setValue={setRent}
          title="How much is your Yearly Rent?"
          value={rent}
          prefix="₹"
        />
        <SelectSection
          index={metro}
          options={["Yes", "No"]}
          setIndex={setMetro}
          title="Do you live in a metro city?"
        />
      </FormLayout>
    </CalculatorLayout>
  );
};

export default HraCalculator;
