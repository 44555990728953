import React from "react";

interface FormLayoutProps {
  children?: React.ReactNode;
}

const FormLayout: React.FC<FormLayoutProps> = ({ children }) => {
  return (
    <div className="md:w-1/2">
      <div className="flex-1 h-full flex flex-col ">
        <div className="h-full px-3 pt-4 pb-8  border-t-0 border-b-0 border-gray100 rounded-t-none md:px-6 md:pt-0 md:pb-0  md:rounded-lg">
          {children}
        </div>
      </div>
    </div>
  );
};

export default FormLayout;
