import React from "react";
import { useWindowDimensions, __md_breakpoint__ } from "../utils";
import FooterCTA from "./footerCTA";

interface CalculatorLayoutProps {
  children?: React.ReactNode;
  direction?: "md:flex-row" | "md:flex-col";
}

const CalculatorLayout: React.FC<CalculatorLayoutProps> = ({
  children,
  direction,
}) => {
  const { width: screenWidth } = useWindowDimensions();
  return (
    <div className="w-full h-full pt-4 bg-white text-sm md:border md:rounded-2xl md:border-black20 font-normal font-sans">
      <div
        className={
          "flex items-stretch px-3 bg-white rounded-lg flex-col md:px-4 md:mb-4 " +
          (direction ? direction : "md:flex-row")
        }
      >
        {children}
      </div>
      {screenWidth >= __md_breakpoint__ && <FooterCTA />}
    </div>
  );
};

export default CalculatorLayout;
