import React, { useMemo, useState } from "react";
import CalculatorLayout from "../components/calculatorLayout";
import ChartLegend from "../components/chartLegend";
import DonutChart from "../components/donutChart";
import FormLayout from "../components/formLayout";
import MultipleNumberFieldSection from "../components/multipleNumberFieldSection";
import NumberFieldSection from "../components/numberFieldSection";
import ResultLayout from "../components/resultLayout";
import { pieColors, formatNumber } from "../utils";

interface InterestRateData {
  principal: number;
  rate: number;
  totalInterest: number;
  totalAmount: number;
}

function calculateInterestRate(
  principal: number,
  duration: number,
  emi: number
): InterestRateData {
  let rate = 1;
  let errorEmi =
    (principal * rate * Math.pow(1 + rate, duration)) /
      (Math.pow(1 + rate, duration) - 1) -
    emi;
  let derivative =
    (principal * Math.pow(1 + rate, duration)) /
      Math.pow(1 + rate, duration - 1) +
    (duration * principal * rate * Math.pow(1 + rate, duration - 1)) /
      Math.pow(Math.pow(1 + rate, duration) - 1, 2);

  while (errorEmi >= 0.00001) {
    rate = rate - errorEmi / derivative;
    errorEmi =
      (principal * rate * Math.pow(1 + rate, duration)) /
        (Math.pow(1 + rate, duration) - 1) -
      emi;
    derivative =
      (principal * Math.pow(1 + rate, duration)) /
        Math.pow(1 + rate, duration - 1) +
      (duration * principal * rate * Math.pow(1 + rate, duration - 1)) /
        Math.pow(Math.pow(1 + rate, duration) - 1, 2);
  }
  rate = Math.round((rate * 100 + Number.EPSILON) * 100 * 12) / 100;
  if (isNaN(rate)) rate = 0;
  const totalInterest = Math.round(emi * duration - principal);
  return {
    principal,
    rate,
    totalAmount: totalInterest + principal,
    totalInterest,
  };
}

const InterestRate: React.FC = () => {
  const [loanAmount, setLoanAmount] = useState<number>(200000);
  const [durationYears, setDurationYears] = useState<number>(10);
  const [durationMonths, setDurationMonths] = useState<number>(0);
  const [emi, setEmi] = useState<number>(2000);

  const interestRateData: InterestRateData = useMemo(
    () =>
      calculateInterestRate(
        loanAmount,
        durationYears * 12 + durationMonths,
        emi
      ),
    [loanAmount, durationYears, durationMonths, emi]
  );

  return (
    <CalculatorLayout>
      <ResultLayout>
        <DonutChart
          data={[
            { name: "Loan Amount", value: interestRateData.principal },
            { name: "Total Interest", value: interestRateData.totalInterest },
          ]}
        />
        <div className="flex flex-col text-black100 md:w-full md:text-black80">
          <div>
            <ChartLegend
              title="Principal"
              value={loanAmount}
              bulletColor={pieColors[0]}
            />
            <ChartLegend
              title="Total Interest"
              value={interestRateData.totalInterest}
              bulletColor={pieColors[1]}
            />
          </div>
        </div>
        <hr className="w-full mt-4 border border-gt20 md:mt-0 md:border-gt40" />
        <div className="flex flex-row items-center w-full pt-2 px-6 md:flex-col md:p-0">
          <div className="w-full flex justify-between items-center text-black80 font-medium md:text-black100">
            <div className="py-2 text-base md:text-xl">Total Amount</div>
            <div className="text-base md:text-lg">
              ₹{formatNumber(interestRateData.totalAmount.toString())}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center w-full px-6 mb-2 md:flex-col md:p-0">
          <div className="w-full flex justify-between items-center text-black80 font-medium md:text-black100">
            <div className="py-2 text-base md:text-xl">Interest Rate</div>
            <div className="text-base md:text-lg">
              {formatNumber(interestRateData.rate.toString())}%
            </div>
          </div>
        </div>
      </ResultLayout>
      <FormLayout>
        <NumberFieldSection
          setValue={setLoanAmount}
          value={loanAmount}
          title="Loan Amount"
          prefix="₹"
        />
        <MultipleNumberFieldSection
          showTotal={false}
          descriptions={["Years", "Months"]}
          title="Duration of your loan"
          values={[durationYears, durationMonths]}
          setValues={[setDurationYears, setDurationMonths]}
          optional={[false, true]}
          maxs={[40, 12]}
          mins={[1, 0]}
          totalDescription=""
        />
        <NumberFieldSection
          setValue={setEmi}
          value={emi}
          title="EMI"
          description="What is your monthly EMI?"
          prefix="₹"
        />
      </FormLayout>
    </CalculatorLayout>
  );
};

export default InterestRate;
