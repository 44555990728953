import React, { useEffect } from "react";
import {
  SliderHandle,
  SliderInput,
  SliderRange,
  SliderTrack,
} from "@reach/slider";

interface SliderProps {
  thumbRef?: React.RefObject<HTMLDivElement>;
  trackRef?: React.RefObject<HTMLDivElement>;
  disabledWidth?: number;
  disabledPosition?: number;
  min?: number;
  max?: number;
  step?: number;
  value: number;
  onChange: (newResponseValue: number) => void;
}

const RangeSlider: React.FC<SliderProps> = ({
  disabledWidth = 0,
  disabledPosition = 0,
  trackRef,
  thumbRef,
  min,
  max,
  step,
  value,
  onChange,
}) => {
  useEffect(() => {
    onChange(value);
  }, []);

  useEffect(() => {
    if (value < disabledPosition) onChange(disabledPosition);
  }, [disabledPosition, onChange, value]);

  return (
    <div className="flex flex-row items-center w-full">
      <div className="text-black40 text-2xs select-none">{min}</div>
      <div className="w-full flex-1 px-4">
        <SliderInput
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(newValue: number) => {
            if (newValue < disabledPosition) onChange(disabledPosition);
            else onChange(newValue);
          }}
        >
          <SliderTrack
            ref={trackRef}
            style={{ background: "#A3E3E3", height: "3px" }}
          >
            <SliderRange style={{ background: "#FC7A69" }} />
            <div
              style={{
                width: disabledWidth > 0 ? disabledWidth + 15 : 0,
                height: "3px",
              }}
              className="absolute bg-black20"
            />
            <SliderHandle
              ref={thumbRef}
              className="focus:outline-none"
              style={{
                background: "#FC7A69",
                height: "18px",
                width: "18px",
                border: "4px solid #075955",
              }}
            />
          </SliderTrack>
        </SliderInput>
      </div>
      <div className="text-black40 text-2xs select-none">{max}</div>
    </div>
  );
};

export default RangeSlider;
