interface CustomFrequncies {
  installmentYear: string;
  prepayment: string;
}

interface Installment {
  installmentNumber: number;
  installmentMonth: string;
  installmentYear: string;
  installmentMonthNumber: number;
  installmentFullDate: string;
  openingBalance: string;
  principal: string;
  monthlyInterest: string;
  monthlyPayment: string;
  partPaymentMade: string;
  monthlyPaymentWithPartPayment: string;
  remainingLoanAmount: string;
  loanPaid: string;
}

interface InstallmentGroup {
  [key: string]: Installment[];
}

function toAmount(amount: number) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
}

export function calculateAndSetHomeLoanEmiData(
  loanAmount: number,
  rateOfInterest: number,
  tenure: number,
  emiStartDate: string,
  prepayment: string,
  processingFees: string,
  prepaymentFrequency: number,
  customPartPaymentSchedule: CustomFrequncies[],
  prepaymentDate: string
) {
  const monthlyInterestRate: number = rateOfInterest / (12 * 100);

  const months: number = tenure * 12;
  const monthlyPayment =
    (loanAmount * monthlyInterestRate) /
    (1 - Math.pow(1 + monthlyInterestRate, -months));

  // remaining loan amount, starting with the initial full loan amount
  let remainingLoanAmount = loanAmount;
  // total interest paid, starting at 0
  let totalInterestPaid = 0;

  // total part payment, starting at 0
  let totalPartPayment = 0;
  // the date of the first installment
  let installmentDate = new Date(emiStartDate);

  let totalPaymentToBePaid = 0;

  // array to hold the schedule
  let schedule: Installment[] = [];

  let amortizationSchedule: Installment[][] = [];

  let amortizationschedule: Installment[][] = [];

  // loop through each month
  for (
    let installmentNumber = 1;
    installmentNumber <= months;
    installmentNumber++
  ) {
    // calculate the interest for the current month
    let monthlyInterest = remainingLoanAmount * monthlyInterestRate;

    // calculate the principal for the current month
    let principal = monthlyPayment - monthlyInterest;

    // opening balance for the current month
    let openingBalance = remainingLoanAmount;
    // initialize part payment made in the current month
    let partPaymentMade = 0;

    totalPaymentToBePaid += monthlyPayment;

    if (
      prepaymentFrequency == 0 ||
      (prepaymentFrequency == 1 && installmentNumber % 12 === 0)
    ) {
      partPaymentMade = Number(prepayment);
    } else if (prepaymentFrequency == 2) {
      // find if there is a custom part payment for the current installment
      // let customPartPayment = customPartPaymentSchedule.find((x) => x.installmentNumber === installmentNumber);
      let customPartPayment =
        customPartPaymentSchedule[customPartPaymentSchedule.length - 1];
      if (customPartPayment) {
        // convert the part payment amount to number
        partPaymentMade = Number(customPartPayment.prepayment);
      }
    }

    // check if a part payment was made in the current month
    if (partPaymentMade) {
      if (prepaymentFrequency == 2) {
        totalPartPayment = Number(
          customPartPaymentSchedule[customPartPaymentSchedule.length - 1]
            .prepayment
        );
      } else {
        // increase the principal by the part payment
        principal += partPaymentMade;
        // reduce the remaining loan amount by the part payment
        remainingLoanAmount -= partPaymentMade;

        // increase the total part payment by the part payment
        totalPartPayment = totalPartPayment + partPaymentMade;
      }
    }

    // reduce the remaining loan amount by the principal
    remainingLoanAmount -= principal;

    // if the remaining loan amount is less than or equal to 0, make adjustments
    if (remainingLoanAmount <= 0) {
      // adjust the principal by adding the negative remaining loan amount
      principal += remainingLoanAmount;
      // increase the total interest paid by the monthly interest
      //   totalInterestPaid += monthlyInterest;
      // set the remaining loan amount to 0
      remainingLoanAmount = 0;
    }

    // increase the total interest paid by the monthly interest
    totalInterestPaid += monthlyInterest;

    const installmentFullDateYear = installmentDate.toLocaleDateString(
      "en-IN",
      {
        year: "numeric",
      }
    );

    const installmentFullDateMonthNumber = installmentDate.toLocaleDateString(
      "en-IN",
      {
        month: "numeric",
      }
    );

    const monthlyInstallmentFullDate =
      installmentFullDateYear +
      "-" +
      installmentFullDateMonthNumber.padStart(2, "0") +
      "-" +
      "01";

    // create an object for the current installment
    let installment: Installment = {
      installmentNumber: installmentNumber,
      installmentMonth: installmentDate.toLocaleDateString("en-IN", {
        month: "short",
      }),
      installmentYear: installmentDate.toLocaleDateString("en-IN", {
        year: "numeric",
      }),
      installmentMonthNumber: Number(
        installmentDate.toLocaleDateString("en-IN", { month: "numeric" })
      ),
      installmentFullDate: monthlyInstallmentFullDate,
      openingBalance: toAmount(openingBalance),
      principal: toAmount(principal),
      monthlyInterest: toAmount(monthlyInterest),
      monthlyPayment: toAmount(monthlyPayment),
      partPaymentMade:
        prepaymentFrequency == 2 ? "0" : toAmount(partPaymentMade),
      monthlyPaymentWithPartPayment: toAmount(monthlyPayment + partPaymentMade),
      remainingLoanAmount: toAmount(remainingLoanAmount),
      loanPaid: (
        ((loanAmount - remainingLoanAmount) / loanAmount) *
        100
      ).toFixed(2),
    };

    installment.partPaymentMade =
      prepaymentFrequency == 0 &&
      installment.installmentFullDate <
        new Date(new Date(prepaymentDate).setDate(1))
          .toISOString()
          .split("T")[0]
        ? "0"
        : prepaymentFrequency == 2
        ? "0"
        : toAmount(partPaymentMade);

    // add the current installment to the schedule
    schedule.push(installment);

    const groupedByYear: InstallmentGroup = {};

    schedule.forEach((row: Installment) => {
      const year = row.installmentYear;
      groupedByYear[year] = groupedByYear[year] || [];
      groupedByYear[year].push(row);
    });

    amortizationschedule = Object.values(groupedByYear);

    amortizationSchedule = amortizationschedule;
    // move the installment date to the next month

    installmentDate.setMonth(installmentDate.getMonth() + 1);

    // if the remaining loan amount is 0 or less, break the loop
    if (remainingLoanAmount <= 0) {
      break;
    }
  }

  return {
    principal: loanAmount,
    monthlyEmi: Math.round(monthlyPayment),
    processingFees: Number(processingFees),
    emiStartDate: emiStartDate,
    amortizationSchedule: amortizationSchedule,
    totalPartPayment: totalPartPayment,
    totalinterest: Math.round(totalInterestPaid),
    monthlyPayment: Math.round(monthlyPayment),
    totalAmount:
      Math.round(Number(loanAmount + totalInterestPaid + totalPartPayment)) +
      Number(processingFees),
    prepaymentFrequency: prepaymentFrequency,
  };
}

export function calculateSavedInterestWithNoPartPayment(
  loanAmount: number,
  rateOfInterest: number,
  tenure: number,
  emiStartDate: string,
  prepayment: string,
  processingFees: string,
  prepaymentFrequency: number,
  customPartPaymentSchedule: CustomFrequncies[],
  prepaymentDate: string
) {
  const monthlyInterestRate: number = rateOfInterest / (12 * 100);

  const months: number = tenure * 12;
  const monthlyPayment =
    (loanAmount * monthlyInterestRate) /
    (1 - Math.pow(1 + monthlyInterestRate, -months));

  // remaining loan amount, starting with the initial full loan amount
  let remainingLoanAmount = loanAmount;
  // total interest paid, starting at 0
  let totalInterestPaid = 0;

  // total part payment, starting at 0
  let totalPartPayment = 0;
  // the date of the first installment

  let installmentDate = new Date(emiStartDate);

  let totalPaymentToBePaid = 0;

  // array to hold the schedule
  let schedule: Installment[] = [];

  let amortizationSchedule: Installment[][] = [];

  let amortizationschedule: Installment[][] = [];

  // loop through each month
  for (
    let installmentNumber = 1;
    installmentNumber <= months;
    installmentNumber++
  ) {
    // calculate the interest for the current month
    let monthlyInterest = remainingLoanAmount * monthlyInterestRate;

    // calculate the principal for the current month
    let principal = monthlyPayment - monthlyInterest;

    // opening balance for the current month
    let openingBalance = remainingLoanAmount;
    // initialize part payment made in the current month
    let partPaymentMade = 0;

    totalPaymentToBePaid += monthlyPayment;

    partPaymentMade = 0;

    // check if a part payment was made in the current month

    totalPartPayment = totalPartPayment + partPaymentMade;

    // reduce the remaining loan amount by the principal
    remainingLoanAmount -= principal;

    // if the remaining loan amount is less than or equal to 0, make adjustments
    if (remainingLoanAmount <= 0) {
      // adjust the principal by adding the negative remaining loan amount
      principal += remainingLoanAmount;
      // increase the total interest paid by the monthly interest
      //   totalInterestPaid += monthlyInterest;
      // set the remaining loan amount to 0
      remainingLoanAmount = 0;
    }

    // increase the total interest paid by the monthly interest
    totalInterestPaid += monthlyInterest;

    const installmentFullDateYear = installmentDate.toLocaleDateString(
      "en-IN",
      {
        year: "numeric",
      }
    );

    const installmentFullDateMonthNumber = installmentDate.toLocaleDateString(
      "en-IN",
      {
        month: "numeric",
      }
    );

    const monthlyInstallmentFullDate =
      installmentFullDateYear +
      "-" +
      installmentFullDateMonthNumber.padStart(2, "0") +
      "-" +
      "01";

    // create an object for the current installment
    let installment: Installment = {
      installmentNumber: installmentNumber,
      installmentMonth: installmentDate.toLocaleDateString("en-IN", {
        month: "short",
      }),
      installmentYear: installmentDate.toLocaleDateString("en-IN", {
        year: "numeric",
      }),
      installmentMonthNumber: Number(
        installmentDate.toLocaleDateString("en-IN", { month: "numeric" })
      ),
      installmentFullDate: monthlyInstallmentFullDate,
      openingBalance: toAmount(openingBalance),
      principal: toAmount(principal),
      monthlyInterest: toAmount(monthlyInterest),
      monthlyPayment: toAmount(monthlyPayment),
      partPaymentMade:
        prepaymentFrequency == 2 ? "0" : toAmount(partPaymentMade),
      monthlyPaymentWithPartPayment: toAmount(monthlyPayment + partPaymentMade),
      remainingLoanAmount: toAmount(remainingLoanAmount),
      loanPaid: (
        ((loanAmount - remainingLoanAmount) / loanAmount) *
        100
      ).toFixed(2),
    };

    installment.partPaymentMade =
      prepaymentFrequency == 0 &&
      installment.installmentFullDate <
        new Date(new Date(prepaymentDate).setDate(1))
          .toISOString()
          .split("T")[0]
        ? "0"
        : prepaymentFrequency == 2
        ? "0"
        : toAmount(partPaymentMade);

    // add the current installment to the schedule
    schedule.push(installment);

    const groupedByYear: InstallmentGroup = {};

    schedule.forEach((row: Installment) => {
      const year = row.installmentYear;
      groupedByYear[year] = groupedByYear[year] || [];
      groupedByYear[year].push(row);
    });

    amortizationschedule = Object.values(groupedByYear);

    amortizationSchedule = amortizationschedule;
    // move the installment date to the next month
    installmentDate.setMonth(installmentDate.getMonth() + 1);

    // if the remaining loan amount is 0 or less, break the loop
    if (remainingLoanAmount <= 0) {
      break;
    }
  }

  return {
    totalinterest: Math.round(totalInterestPaid),
  };
}

export function calculateSavedInterset(
  loanAmount: number,
  rateOfInterest: number,
  tenure: number,
  emiStartDate: string,
  prepayment: string,
  processingFees: string,
  prepaymentFrequency: number,
  customPartPaymentSchedule: CustomFrequncies[],
  prepaymentDate: string
) {
  // calculate the monthly interest rate
  const monthlyInterestRate: number = rateOfInterest / (12 * 100);

  const months: number = tenure * (75 / 100) * 12;
  const monthlyPayment =
    (loanAmount * monthlyInterestRate) /
    (1 - Math.pow(1 + monthlyInterestRate, -months));

  // remaining loan amount, starting with the initial full loan amount
  let remainingLoanAmount = loanAmount;
  // total interest paid, starting at 0
  let totalInterestPaid = 0;

  // total part payment, starting at 0
  let totalPartPayment = 0;
  // the date of the first installment
  let installmentDate = new Date(emiStartDate);

  let totalPaymentToBePaid = 0;

  // array to hold the schedule
  let schedule = [];

  let amortizationSchedule: Installment[][] = [];

  let amortizationschedule: Installment[][] = [];

  // loop through each month
  for (
    let installmentNumber = 1;
    installmentNumber <= months;
    installmentNumber++
  ) {
    // calculate the interest for the current month
    let monthlyInterest = remainingLoanAmount * monthlyInterestRate;

    // calculate the principal for the current month
    let principal = monthlyPayment - monthlyInterest;

    // opening balance for the current month
    let openingBalance = remainingLoanAmount;
    // initialize part payment made in the current month
    let partPaymentMade = 0;

    totalPaymentToBePaid += monthlyPayment;

    if (
      prepaymentFrequency == 0 ||
      (prepaymentFrequency == 1 && installmentNumber % 12 === 0)
    ) {
      partPaymentMade = Number(prepayment);
    } else if (prepaymentFrequency == 2) {
      let customPartPayment =
        customPartPaymentSchedule[customPartPaymentSchedule.length - 1];
      if (customPartPayment) {
        // convert the part payment amount to number
        // partPaymentMade = Number(customPartPayment.prepayment.replace(/[^0-9.-]+/g, ""));
        partPaymentMade = Number(customPartPayment.prepayment);
      }
    }

    // check if a part payment was made in the current month
    if (partPaymentMade) {
      if (prepaymentFrequency == 2) {
        totalPartPayment = Number(
          customPartPaymentSchedule[customPartPaymentSchedule.length - 1]
            .prepayment
        );
      } else {
        // increase the principal by the part payment
        principal += partPaymentMade;
        // reduce the remaining loan amount by the part payment
        remainingLoanAmount -= partPaymentMade;
        // increase the total part payment by the part payment
        // totalPartPayment += partPaymentMade;

        totalPartPayment = totalPartPayment + partPaymentMade;
      }
    }

    // reduce the remaining loan amount by the principal
    remainingLoanAmount -= principal;

    // if the remaining loan amount is less than or equal to 0, make adjustments
    if (remainingLoanAmount <= 0) {
      // adjust the principal by adding the negative remaining loan amount
      principal += remainingLoanAmount;
      // increase the total interest paid by the monthly interest
      //   totalInterestPaid += monthlyInterest;
      // set the remaining loan amount to 0
      remainingLoanAmount = 0;
    }

    // increase the total interest paid by the monthly interest
    totalInterestPaid += monthlyInterest;

    const installmentFullDateYear = installmentDate.toLocaleDateString(
      "en-IN",
      {
        year: "numeric",
      }
    );

    const installmentFullDateMonthNumber = installmentDate.toLocaleDateString(
      "en-IN",
      {
        month: "numeric",
      }
    );

    const monthlyInstallmentFullDate =
      installmentFullDateYear +
      "-" +
      installmentFullDateMonthNumber.padStart(2, "0") +
      "-" +
      "01";

    // create an object for the current installment
    let installment: Installment = {
      installmentNumber: installmentNumber,
      installmentMonth: installmentDate.toLocaleDateString("en-IN", {
        month: "short",
      }),
      installmentYear: installmentDate.toLocaleDateString("en-IN", {
        year: "numeric",
      }),
      installmentMonthNumber: Number(
        installmentDate.toLocaleDateString("en-IN", { month: "numeric" })
      ),
      installmentFullDate: monthlyInstallmentFullDate,
      openingBalance: toAmount(openingBalance),
      principal: toAmount(principal),
      monthlyInterest: toAmount(monthlyInterest),
      monthlyPayment: toAmount(monthlyPayment),
      partPaymentMade:
        prepaymentFrequency == 2 ? "0" : toAmount(partPaymentMade),
      monthlyPaymentWithPartPayment: toAmount(monthlyPayment + partPaymentMade),
      remainingLoanAmount: toAmount(remainingLoanAmount),
      loanPaid: (
        ((loanAmount - remainingLoanAmount) / loanAmount) *
        100
      ).toFixed(2),
    };

    installment.partPaymentMade =
      prepaymentFrequency == 0 &&
      installment.installmentFullDate <
        new Date(new Date(prepaymentDate).setDate(1))
          .toISOString()
          .split("T")[0]
        ? "0"
        : prepaymentFrequency == 2
        ? "0"
        : toAmount(partPaymentMade);

    // add the current installment to the schedule
    schedule.push(installment);

    const groupedByYear: InstallmentGroup = {};

    schedule.forEach((row: Installment) => {
      const year = row.installmentYear;
      groupedByYear[year] = groupedByYear[year] || [];
      groupedByYear[year].push(row);
    });

    amortizationschedule = Object.values(groupedByYear);

    amortizationSchedule = amortizationschedule;
    // move the installment date to the next month
    installmentDate.setMonth(installmentDate.getMonth() + 1);

    // if the remaining loan amount is 0 or less, break the loop
    if (remainingLoanAmount <= 0) {
      break;
    }
  }

  return {
    totalinterest: Math.round(totalInterestPaid),
  };
}
