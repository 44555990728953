import React, { useMemo } from "react";
import CalculatorLayout from "../components/calculatorLayout";
import ChartLegend from "../components/chartLegend";
import DonutChart from "../components/donutChart";
import FormLayout from "../components/formLayout";
import MultipleNumberFieldSection from "../components/multipleNumberFieldSection";
import NumberFieldSection from "../components/numberFieldSection";
import ResultLayout from "../components/resultLayout";
import { formatNumber, pieColors } from "../utils";

interface EmiData {
  emi: number;
  totalAmount: number;
  totalInterest: number;
}

function calculateEmi(
  amount: number,
  rate: number,
  durationYears: number,
  durationMonths: number
): EmiData {
  let emi;
  let totalAmount;
  if (rate) {
    emi =
      (amount *
        (rate / 1200) *
        Math.pow(1 + rate / 1200, durationYears * 12 + durationMonths)) /
      (Math.pow(1 + rate / 1200, durationYears * 12 + durationMonths) - 1);

    totalAmount = Math.round(emi) * durationYears * 12 + durationMonths;
  } else {
    emi = amount / (durationYears * 12 + durationMonths);
    totalAmount = amount;
  }
  return {
    emi: Math.round(emi),
    totalAmount,
    totalInterest: totalAmount - amount,
  };
}

const EmiCalculator: React.FC = () => {
  const [loanAmount, setLoanAmount] = React.useState(100000);
  const [rate, setRate] = React.useState(8.5);
  const [durationYears, setDurationYears] = React.useState(5);
  const [durationMonths, setDurationMonths] = React.useState(0);

  const emiData = useMemo(
    () => calculateEmi(loanAmount, rate, durationYears, durationMonths),
    [durationMonths, durationYears, loanAmount, rate]
  );

  return (
    <CalculatorLayout>
      <ResultLayout>
        <DonutChart
          data={[
            { name: "Loan Amount", value: loanAmount },
            { name: "Total Interest", value: emiData.totalInterest },
          ]}
        />
        <div className="flex flex-col text-black100 md:w-full md:text-black80">
          <div>
            <ChartLegend
              title="Principal"
              value={loanAmount}
              bulletColor={pieColors[0]}
            />
            <ChartLegend
              title="Total Interest"
              value={emiData.totalInterest}
              bulletColor={pieColors[1]}
            />
          </div>
        </div>
        <hr className="w-full mt-4 border border-gt20 md:mt-0 md:border-gt40" />
        <div className="flex flex-row items-center w-full pt-2 px-6 md:flex-col md:p-0">
          <div className="w-full flex justify-between items-center text-black80 font-medium md:text-black100">
            <div className="py-2 text-base md:text-xl">Total Amount</div>
            <div className="text-base md:text-lg">
              ₹{formatNumber(emiData.totalAmount.toString())}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center w-full px-6 mb-2 md:flex-col md:p-0">
          <div className="w-full flex justify-between items-center text-black80 font-medium md:text-black100">
            <div className="py-2 text-base md:text-xl">EMI</div>
            <div className="text-base md:text-lg">
              ₹{formatNumber(emiData.emi.toString())}
            </div>
          </div>
        </div>
      </ResultLayout>

      <FormLayout>
        <NumberFieldSection
          setValue={setLoanAmount}
          title="Loan Amount"
          value={loanAmount}
          prefix="₹"
        />
        <NumberFieldSection
          setValue={setRate}
          title="Rate of Interest"
          description="What is the Rate of Interest per year?"
          value={rate}
          allowDecimal={true}
          min={0}
          max={50}
        />
        <MultipleNumberFieldSection
          showTotal={false}
          descriptions={["Years", "Months"]}
          title="Duration of your loan"
          values={[durationYears, durationMonths]}
          setValues={[setDurationYears, setDurationMonths]}
          optional={[false, true]}
          maxs={[40, 12]}
          mins={[1, 0]}
          totalDescription=""
        />
      </FormLayout>
    </CalculatorLayout>
  );
};

export default EmiCalculator;
