import React, { useRef, useState, useEffect } from "react";
import MainLayout from "../../components/v2/mainLayout";
import DonutResult from "../../components/v2/donutResult";
import QuestionLayout from "../../components/v2/formLayout";
import SliderSection from "../../components/v2/sliderSection";
import Banner from "../../components/v2/banner";
import DropDown from "../../components/v2/dropDown";
import { formatNumber } from "../../utils";
import ScheduleTable from "./scheduleTable";
import {
  calculateAndSetHomeLoanEmiData,
  calculateSavedInterestWithNoPartPayment,
  calculateSavedInterset,
} from "./utils";

const prepaymentFrequencies: string[] = ["per month", "per year", "one time"];

interface CustomFrequncies {
  installmentYear: string;
  prepayment: string;
}

function toAmount(amount: number) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
}

const HomeLoanEmiCalculator: React.FC = () => {
  const [loanAmount, setLoanAmount] = useState<number>(5000000);
  const [rateOfInterest, setRateOfInterest] = useState<number>(9);
  const [tenure, setTenure] = useState<number>(20);
  const [emiStartDate, setEmiStartDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );

  const [processingFees, setProcessingFees] = useState<string>("");
  const [prepayment, setPrepayment] = useState<string>("");
  const [prepaymentDate, setPrepaymentDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [prepaymentFrequency, setPrepaymentFrequency] = useState<number>(0);
  const [customPartPaymentSchedule, setCustomPartPaymentSchedule] = useState<
    CustomFrequncies[]
  >([]);
  const [isPrepayment, showPrepayment] = useState<boolean>(false);
  const [isProcessingFees, showProcessingFees] = useState<boolean>(false);
  const [showschedule, showSchedule] = useState<boolean>(false);

  const setCustomPrepayment = (prepayment: string, prepaymentDate: string) => {
    const installmentYearCustom =
      prepaymentDate && new Date(prepaymentDate).getFullYear();

    let curentInstallment = {
      installmentYear: installmentYearCustom.toString(),
      prepayment: prepayment,
    };

    // add the current installment to the customPartPaymentSchedule
    customPartPaymentSchedule.push(curentInstallment);
  };

  const homeLoanEmiData = calculateAndSetHomeLoanEmiData(
    loanAmount,
    rateOfInterest,
    tenure,
    emiStartDate,
    prepayment,
    processingFees,
    prepaymentFrequency,
    customPartPaymentSchedule,
    prepaymentDate
  );

  const savedInterestDataWithNoPartpayment =
    calculateSavedInterestWithNoPartPayment(
      loanAmount,
      rateOfInterest,
      tenure,
      emiStartDate,
      prepayment,
      processingFees,
      prepaymentFrequency,
      customPartPaymentSchedule,
      prepaymentDate
    );

  const savedInterestData = calculateSavedInterset(
    loanAmount,
    rateOfInterest,
    tenure,
    emiStartDate,
    prepayment,
    processingFees,
    prepaymentFrequency,
    customPartPaymentSchedule,
    prepaymentDate
  );

  const thumbRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);

  const lastScheduleData =
    homeLoanEmiData.amortizationSchedule[
      homeLoanEmiData.amortizationSchedule.length - 1
    ];

  const prepaymentMaxYear =
    homeLoanEmiData.amortizationSchedule.length > 0
      ? lastScheduleData[lastScheduleData.length - 1].installmentYear
      : new Date(prepaymentDate).getFullYear();
  const prepaymentMaxMonth =
    homeLoanEmiData.amortizationSchedule.length > 0
      ? lastScheduleData[
          lastScheduleData.length - 1
        ].installmentMonthNumber.toString()
      : new Date(prepaymentDate).toLocaleString("default", { month: "short" });

  const prepaymentMaxDate =
    prepaymentMaxMonth.padStart(2, "0") + "-" + "01" + "-" + prepaymentMaxYear;

  return (
    <div
      className="calculators-v2-layout"
      style={{ maxWidth: "1280px", margin: "0 auto" }}
    >
      <div
        className="home-illustartion flex justify-center md:mb-4 mt-10 flex"
        style={{ position: "relative", width: "100%" }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "90%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src="/assets/home-illustration-1.png"
            style={{
              position: "absolute",
              display: loanAmount < 12487500 ? "block" : "none",
              objectFit: "contain",
            }}
            className="md:h-full h-auto"
            alt="home-illustration-1"
          ></img>
          <img
            src="/assets/home-illustration-2.png"
            style={{
              position: "absolute",
              display:
                loanAmount > 12487501 && loanAmount < 24975000
                  ? "block"
                  : "none",
              objectFit: "contain",
            }}
            className="md:h-full h-auto"
            alt="home-illustration-2"
          ></img>
          <img
            src="/assets/home-illustration-3.png"
            style={{
              position: "absolute",
              display:
                loanAmount > 24975001 && loanAmount < 37462500
                  ? "block"
                  : "none",
              objectFit: "contain",
            }}
            className="md:h-full h-auto"
            alt="home-illustration-3"
          ></img>
          <img
            src="/assets/home-illustration-4.png"
            style={{
              position: "absolute",
              display:
                loanAmount > 37462501 && loanAmount < 49950000
                  ? "block"
                  : "none",
              objectFit: "contain",
            }}
            className="md:h-full h-auto"
            alt="home-illustration-4"
          ></img>
          <img
            src="/assets/home-illustration-5.png"
            style={{
              position: "absolute",
              display:
                loanAmount > 49950001 && loanAmount < 62437500
                  ? "block"
                  : "none",
              objectFit: "contain",
            }}
            className="md:h-full h-auto"
            alt="home-illustration-5"
          ></img>
          <img
            src="/assets/home-illustration-6.png"
            style={{
              position: "absolute",
              display:
                loanAmount > 62437501 && loanAmount < 74925000
                  ? "block"
                  : "none",
              objectFit: "contain",
            }}
            className="md:h-full h-auto"
            alt="home-illustration-6"
          ></img>
          <img
            src="/assets/home-illustration-7.png"
            style={{
              position: "absolute",
              display:
                loanAmount > 74925001 && loanAmount < 87412500
                  ? "block"
                  : "none",
              objectFit: "contain",
            }}
            className="md:h-full h-auto"
            alt="home-illustration-7"
          ></img>
          <img
            src="/assets/home-illustration-8.png"
            style={{
              position: "absolute",
              display: loanAmount > 87412501 ? "block" : "none",
              objectFit: "contain",
            }}
            className="md:h-full h-auto"
            alt="home-illustration-8"
          ></img>
        </div>
      </div>

      <MainLayout>
        <QuestionLayout>
          <SliderSection
            title="Loan Amount:"
            value={loanAmount}
            setValue={setLoanAmount}
            step={50000}
            prefix="₹ "
            suffix=""
            min={100000}
            max={100000000}
            thumbRef={thumbRef}
            trackRef={trackRef}
          />

          <SliderSection
            title="Rate of interest:"
            value={rateOfInterest}
            setValue={setRateOfInterest}
            step={0.01}
            min={6}
            max={18}
            suffix=" %"
          />
          <SliderSection
            title="Loan tenure:"
            value={tenure}
            setValue={setTenure}
            step={0.5}
            min={2}
            max={30}
            suffix=" years"
          />
          {tenure >= 4 && (
            <div className="md: mt-2 mt-4">
              <Banner
                description={`Save up to ₹ ${formatNumber(
                  (
                    homeLoanEmiData.totalinterest -
                    savedInterestData.totalinterest
                  ).toString()
                )} in interest by opting for a tenure of ${Math.round(
                  tenure * (75 / 100)
                )} years`}
              />
            </div>
          )}
        </QuestionLayout>
        <div
          className="flex flex-col md:w-1/2 border border-lightBorderSecondary rounded-lg px-6 pb-6 pt-6 h-full"
          style={{ borderRadius: "16px" }}
        >
          <div className="flex flex-row justify-between  w-full mb-4 md:justify-between md:pt-0">
            <span className="text-lightContentAccent flex md:flex-row md:items-center flex flex-col">
              <span className="text-2xl mr-3">EMI</span>
              <span className="text-sm">(Monthly payments)</span>
            </span>
            <span className="text-2xl text-lightContentAccent flex items-center">
              {" "}
              <span className="text-base mr-1">₹</span>{" "}
              {formatNumber(homeLoanEmiData.monthlyEmi.toString())}
            </span>
          </div>
          <div className="flex flex-row w-full">
            <DonutResult
              data={[
                {
                  name: "Principal amount",
                  value: homeLoanEmiData.principal,
                },
                {
                  name: "Total interest",
                  value: homeLoanEmiData.totalinterest,
                },
                {
                  name: "Processing fees",
                  value: homeLoanEmiData.processingFees,
                },
                {
                  name: "Pre-payment",
                  value: Math.round(homeLoanEmiData.totalPartPayment),
                },
              ]}
              totalData={{
                name: "Total Amount paid:",
                value: homeLoanEmiData.totalAmount,
              }}
            />
          </div>
        </div>
      </MainLayout>
      {isPrepayment && (
        <div className="px-3 mt-10">
          <div
            className="px-4 flex-col md:flex-row pb-4 pb-4 pt-3 md:pb-6 md:pt-6 border border-lightBorderSecondary md:px-6 md:border-b flex w-full justify-between prepayment-wrapper"
            style={{ borderRadius: "16px" }}
          >
            <div className="md:w-1/2 flex-col flex w-full">
              <div className="flex flex-row" style={{ alignItems: "center" }}>
                <div className="flex md:flex-row flex-col w-full">
                  <div
                    className="flex justify-between md:justify-start mb-6 md:mb-2"
                    style={{ alignItems: "center" }}
                  >
                    <span className="text-sm text-lightContentSecondary">
                      Pre-payment amount:
                    </span>
                    <div className="flex" style={{ alignItems: "center" }}>
                      <input
                        style={{ width: "8ch", fontWeight: 600 }}
                        className="ml-2 border-b border-lightContentPrimary focus:outline-none text-lightContentPrimary"
                        value={prepayment}
                        type="number"
                        onChange={(e) => {
                          setPrepayment(e.target.value);
                          setCustomPrepayment(
                            e.target.value,
                            prepaymentDate ? prepaymentDate : ""
                          );
                        }}
                      />
                      <div className="ml-2">
                        <DropDown
                          index={prepaymentFrequency}
                          options={prepaymentFrequencies}
                          setIndex={setPrepaymentFrequency}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex mb-6 md:mb-2 md:w-2/5 justify-between md:justify-start"
                    style={{ alignItems: "center" }}
                  >
                    {prepaymentFrequency == 2 ? (
                      <span className="text-sm text-lightContentSecondary md:ml-3 md:mr-3">
                        On:
                      </span>
                    ) : (
                      <span className="text-sm text-lightContentSecondary md:ml-2 md:mr-2">
                        Starting from:
                      </span>
                    )}

                    <div
                      style={{ position: "relative", width: "25%", right: 0 }}
                    >
                      <div
                        className="flex border-b border-lightContentPrimary"
                        style={{
                          alignItems: "center",
                          position: "absolute",
                          top: -12,
                        }}
                      >
                        <span
                          className="text-md text-lightContentPrimary"
                          style={{ fontWeight: 600 }}
                        >
                          {prepaymentDate &&
                            new Date(prepaymentDate).toLocaleString("default", {
                              month: "short",
                            })}
                          &nbsp;
                        </span>
                        <span className="text-sm text-lightContentSecondary">
                          {prepaymentDate &&
                            new Date(prepaymentDate).getFullYear()}
                          &nbsp;
                        </span>

                        <img
                          className="w-2 h-2 mx-2 my-2"
                          style={{ cursor: "pointer" }}
                          src="/assets/down-chevron.svg"
                          alt="down-chevron"
                        />
                      </div>
                      <input
                        type="date"
                        min={
                          prepaymentDate
                            ? new Date(emiStartDate).toISOString().split("T")[0]
                            : new Date().toLocaleDateString("en-CA")
                        }
                        max={
                          prepaymentDate
                            ? prepaymentMaxDate
                            : new Date().toLocaleDateString("en-CA")
                        }
                        id="prepaymentDate"
                        name="prepaymentDate"
                        value={prepaymentDate}
                        onChange={(e) => {
                          setPrepaymentDate(e.target.value);
                          setCustomPrepayment(
                            prepayment ? prepayment : "",
                            e.target.value
                          );
                        }}
                        style={{
                          position: "absolute",
                          left: "0px",
                          opacity: 0,
                          top: -14,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {prepaymentFrequency == 0 && (
                <div
                  className="md:mt-2 md:mb-4 mb-6 flex flex-row justify-between md:justify-start"
                  style={{ alignItems: "center" }}
                >
                  <div className="flex md:flex-row flex-col">
                    <span className="text-sm text-lightContentSecondary">
                      Total monthly payment
                    </span>
                    <span className="text-sm text-lightContentSecondary">
                      (EMI + pre-payment):
                    </span>
                  </div>

                  <div className="flex items-center">
                    <span className="text-sm ml-2">₹</span>
                    <span
                      className="ml-1 mr-1 text-base"
                      style={{ fontWeight: 600 }}
                    >
                      {formatNumber(
                        (
                          Number(homeLoanEmiData.monthlyPayment) +
                          Number(prepayment)
                        ).toString()
                      )}
                    </span>
                    <span className="text-sm text-lightContentSecondary">
                      {" "}
                      /month
                    </span>
                  </div>
                </div>
              )}
              <Banner
                description={`You will save ₹ ${formatNumber(
                  (
                    savedInterestDataWithNoPartpayment.totalinterest -
                    homeLoanEmiData.totalinterest
                  ).toString()
                )} in interest with this pre-payment plan`}
              />
            </div>
            <div className="md:w-1/2 mt-4 md:mt-0 md:text-right">
              <span
                className="text-sm"
                style={{
                  borderBottom: "solid 1px #B7352A",
                  color: "#B7352A",
                  cursor: "pointer",
                  fontWeight: 700,
                }}
                onClick={() => {
                  showPrepayment(false);
                  setPrepayment("");
                  setCustomPartPaymentSchedule([]);
                }}
              >
                Remove
              </span>
            </div>
          </div>
        </div>
      )}
      {isProcessingFees && (
        <div className="px-3 mt-6">
          <div
            className="px-3 flex-col md:flex-row pb-3 pt-3 md:pb-6 md:pt-6 border border-lightBorderSecondary rounded-lg rounded-t-none md:px-6 md:border-b md:rounded-lg flex w-full justify-between prpcessingfees-wrapper"
            style={{ borderRadius: "16px" }}
          >
            <div className="md:w-1/2 flex flex-row w-full justify-between md:justify-start">
              <div>
                <span className="text-lightContentSecondary text-md mr-2">
                  Processing fees:
                </span>
              </div>
              <div>
                <span className="mr-1 text-lightContentSecondary">₹</span>
                <input
                  style={{ width: "8ch", fontWeight: 600 }}
                  className="border-b border-lightContentPrimary focus:outline-none text-lightContentPrimary"
                  value={processingFees}
                  type="number"
                  onChange={(e) => {
                    setProcessingFees(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="md:w-1/2 mt-4 md:mt-0 md:text-right">
              <span
                className="text-sm"
                style={{
                  borderBottom: "solid 1px #B7352A",
                  color: "#B7352A",
                  cursor: "pointer",
                  fontWeight: 700,
                }}
                onClick={() => {
                  showProcessingFees(false);
                  setProcessingFees("");
                }}
              >
                Remove
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col md:w-full mb-8 px-4 mt-8 md:flex-row">
        {!isPrepayment && (
          <p
            className="underline underline-offset-8 text-sm cursor-pointer mr-4 mb-4"
            style={{ cursor: "pointer", color: "#313339", fontWeight: 700 }}
            onClick={() => showPrepayment(true)}
          >
            {" "}
            + Add Pre-payments
          </p>
        )}
        {!isProcessingFees && (
          <p
            className="underline underline-offset-8 text-sm cursor-pointer"
            style={{ cursor: "pointer", color: "#313339", fontWeight: 700 }}
            onClick={() => showProcessingFees(true)}
          >
            {" "}
            + Add processing fees
          </p>
        )}
      </div>

      <div className="flex justify-center mt-0 mb-12 w-full">
        <div className="w-full px-3">
          <div style={{ position: "relative", textAlign: "center" }}>
            <span
              className="mb-2 loan-table-header flex justify-center"
              style={{ textAlign: "center", background: "#FFF" }}
            >
              <span className="absolute top-3 left-0 border-b border-dashed border-gray100 w-1/6 md:w-2/5"></span>
              <div
                className="flex justify-center cursor-pointer"
                onClick={() => showSchedule(!showschedule)}
              >
                <span className="text-lg " style={{ fontWeight: 700 }}>
                  Amortization schedule
                </span>
                {showschedule ? (
                  <img
                    className="w-4 h-4 ml-2 mr-0 my-2 cursor-pointer"
                    src="/assets/up-chevron.svg"
                    alt="up-chevron"
                  />
                ) : (
                  <img
                    className="w-4 h-4 ml-2 mr-0 my-2 cursor-pointer"
                    src="/assets/down-chevron.svg"
                    alt="down-chevron"
                  />
                )}
              </div>
              <span className="absolute top-3 right-0 border-b border-dashed border-gray100 w-1/6 md:w-2/5"></span>
            </span>
          </div>
          {showschedule && (
            <div>
              <div className="flex justify-center  mb-8">
                <div
                  className="flex rounded px-3 pb-1 pt-1"
                  style={{ width: 204 }}
                >
                  <span
                    className="text-sm text-lightContentSecondary"
                    style={{ marginTop: 2 }}
                  >
                    EMI start month:&nbsp;
                  </span>

                  <div style={{ position: "relative" }}>
                    <div
                      className="flex"
                      style={{ alignItems: "center", position: "absolute" }}
                    >
                      <span className="text-md">
                        {emiStartDate &&
                          new Date(emiStartDate).toLocaleString("default", {
                            month: "short",
                          })}
                        &nbsp;
                      </span>
                      <span className="text-sm text-lightContentSecondary">
                        {emiStartDate && new Date(emiStartDate).getFullYear()}
                        &nbsp;
                      </span>

                      <img
                        className="w-2 h-2 mx-2 my-2"
                        src="/assets/down-chevron.svg"
                        alt="down-chevron"
                      />
                    </div>
                    <input
                      type="date"
                      id="emiStartDate"
                      name="emiStartDate"
                      value={emiStartDate}
                      onChange={(e) => {
                        setEmiStartDate(e.target.value);
                        setPrepaymentDate(e.target.value);
                      }}
                      style={{
                        position: "absolute",
                        left: "0px",
                        opacity: 0,
                        top: -2,
                      }}
                    />
                  </div>
                </div>
              </div>
              <ScheduleTable
                homeLoanEmiData={homeLoanEmiData}
                prepaymentFrequency={
                  prepaymentFrequency ? prepaymentFrequency : 1
                }
                prepaymentDate={prepaymentDate}
              />
            </div>
          )}
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default HomeLoanEmiCalculator;
