import React from "react";
import { formatNumber } from "../../utils";

interface ChartLegendProps {
  bulletColor: string;
  suffix?: string;
  title: string;
  value: number;
  textSize?: "text-xs" | "text-sm";
  smDirection?: "flex-col" | "flex-row";
  smMarginY?: "my-6" | "my-1";
}

const ChartLegend: React.FC<ChartLegendProps> = ({
  bulletColor,
  title,
  suffix,
  value,
  textSize = "text-xs",
  smDirection = "flex-col",
  smMarginY = "my-4",
}) => {
  return (
    <div
      className={
        "flex justify-between py-4 md:py-2 items-start text-black100 md:flex-row md:items-center md:my-0 flex-row "
      }
      style={{ borderBottom: "dashed 1px rgb(118, 118, 0.24)" }}
    >
      <div className="flex flex-1 items-center md:py-1">
        <div
          style={{
            minWidth: "0.75rem",
            minHeight: "0.75rem",
            borderRadius: "4px",
          }}
          className={`mr-3 ${bulletColor}`}
        />
        <div className="text-sm text-lightContentSecondary">{title}</div>
      </div>
      <div className="ml-6 text-sm text-lightContentSecondary">
        {(suffix ? "" : "₹ ") + formatNumber(value.toString()) + (suffix ?? "")}
      </div>
    </div>
  );
};

export default ChartLegend;
