import React from "react";

interface BarLabelProps {
  x?: number;
  y?: number;
  fill?: string;
  rate: string;
}

const BarLabel: React.FC<BarLabelProps> = ({ x, y, fill, rate }) => {
  return (
    <text
      x={(x ?? 0) + 30}
      className="bar-label"
      y={Math.round((y ?? 0) + ((y ?? 0) < 60 ? 60 : 40))}
      dy={-4}
      fontSize="10"
      fontFamily="sans-serif"
      color="red"
      fill={fill}
      textAnchor="middle"
    >
      {rate.endsWith(".5") ? rate : Math.round(parseFloat(rate))}%
    </text>
  );
};

export default BarLabel;
