import React, { useMemo } from "react";
import Tooltip from "../components/tooltip";
import CalculatorLayout from "../components/calculatorLayout";
import FormLayout from "../components/formLayout";
import MultipleNumberFieldSection from "../components/multipleNumberFieldSection";
import NumberFieldSection from "../components/numberFieldSection";
import { formatNumber } from "../utils";

interface GratuityData {
  covered: number;
  uncovered: number;
}

function calculateGratuity(
  salary: number,
  durationYears: number,
  durationMonths: number
): GratuityData {
  const covered = Math.round(
    ((salary * 15) / 26) * Math.round(durationYears + durationMonths / 12)
  );
  const uncovered = Math.round(
    ((salary * 15) / 30) * Math.round(durationYears + durationMonths / 12)
  );
  return { covered, uncovered };
}

const GratuityCalculator: React.FC = () => {
  const [salary, setSalary] = React.useState(40000);
  const [durationYears, setDurationYears] = React.useState(5);
  const [durationMonths, setDurationMonths] = React.useState(0);

  const gratuityData: GratuityData = useMemo(
    () => calculateGratuity(salary, durationYears, durationMonths),
    [salary, durationYears, durationMonths]
  );

  return (
    <CalculatorLayout>
      <div
        className={
          "sticky top-0 z-50 p-3 mr-3 bg-white rounded-lg rounded-b-none border border-gray100 w-full md:static md:p-0 md:border-none " +
          "md:w-2/5 lg:w-1/3"
        }
      >
        <div className="pb-3 text-center text-lg text-gt60 md:hidden ">
          Calculate Instantly
        </div>
        <div
          className={
            "flex items-start justify-between w-full h-full bg-gt40 rounded-lg p-4 md:bg-gt20 flex-col"
          }
        >
          <div className="text-lg font-medium my-4 md:mb-0">
            Gratuity Amount
          </div>
          <div className="text-base pl-4 mb-4 md:mb-0">
            If you're covered
            <div className="text-lg font-medium">
              ₹{formatNumber(gratuityData.covered.toString())}
            </div>
          </div>
          <div className="text-base pl-4">
            If you're not covered
            <div className="text-lg font-medium">
              ₹{formatNumber(gratuityData.uncovered.toString())}
            </div>
          </div>
          <Tooltip
            assumptions={[
              "The months you've worked are rounded off to the nearest year.",
            ]}
          />
        </div>
      </div>
      <FormLayout>
        <NumberFieldSection
          setValue={setSalary}
          title="Monthly Salary"
          description="What is the your Basic Monthly Salary including Dearness Allowance"
          value={salary}
          prefix="₹"
        />
        <MultipleNumberFieldSection
          showTotal={false}
          descriptions={["Years", "Months"]}
          title="How many years have you worked for this company?"
          values={[durationYears, durationMonths]}
          setValues={[setDurationYears, setDurationMonths]}
          optional={[false, true]}
          maxs={[0, 12]}
          mins={[5, 0]}
          totalDescription=""
        />
      </FormLayout>
    </CalculatorLayout>
  );
};

export default GratuityCalculator;
