import React from "react";

interface QuestionTitleProps {
  responsePreview?: string;
  title: string;
  error?: boolean;
}

const QuestionTitle: React.FC<QuestionTitleProps> = ({
  error,
  responsePreview,
  title,
}) => {
  return (
    <div className="flex flex-row items-center justify-between md:mb-1">
      <div className="flex items-center">
        <div
          className={
            "flex-1 text-base md:text-lg md:font-medium " +
            (error ? "text-red-500" : "text-black80")
          }
        >
          {title}
        </div>
      </div>
      <div className="min-w-max text-black80 text-sm">{responsePreview}</div>
    </div>
  );
};

export default QuestionTitle;
